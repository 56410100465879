/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 7/18/2023
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../_contexts/UserContext";
import Checkbox from "../_ui/formElements/Checkbox";
import {TICKET_PRIORITIES} from "../../system/defaults";
import SelectorInput from "../_ui/formElements/SelectorInput";

const TicketTask = ({task, name, label, checked, changeHandler, editHandler, deleteHandler, classes})=>{
    const {userContext} = useContext(UserContext);
    const [action, setAction] = useState('');
    const [editing, setEditing] = useState(false);
    const [editLabel, setEditLabel] = useState('');

    useEffect(()=>{
        setEditLabel(task.instruction);
    },[]);

    return (
        <div className={`d-flex align-items-start justify-content-start border-bottom pt-2`}>
            {renderSwitch()}
            {parseInt(userContext.contact.group_id) <= 4 && action === '' ? <>
            <i className={`bi bi-pencil ps-3`} onClick={()=>setAction(()=>'edit')} />
            <i className={`bi bi-trash ps-2`} onClick={()=>setAction(()=>'delete')} />
            </> : '' }
        </div>
    );

    function renderSwitch(){
        switch(action){
            case 'edit':
                return (<div className='d-flex justify-content-end align-items-start w-100 pb-2'>
                    <textarea value={editLabel} className='flex-grow-1' onChange={(e)=>setEditLabel(e.target.value)} />
                    <input type='button' value='Save' className='btn btn-sm btn-success ms-3' onClick={saveChanges} />
                    <input type='button' value='Cancel' className='btn btn-sm btn-danger ms-1' onClick={()=>setAction('')}/>
                </div>);
            case 'delete':
            return (<div className='d-flex justify-content-end align-items-center w-100 pb-2'>
                Are you sure?
                <input type='button' value='Yes' className='btn btn-sm btn-success ms-3' onClick={()=>deleteHandler(true)} />
                <input type='button' value='Cancel' className='btn btn-sm btn-danger ms-1' onClick={()=>setAction('')}/>
            </div>);
            default:
                return <Checkbox name={name}
                                 checked={checked}
                                 label={task.instruction}
                                 changeHandler={changeHandler}
                                 classes={`mb-2 flex-grow-1`}
                />
        }
    }

    function saveChanges(){
        setAction('');
        editHandler(editLabel);
    }
};

export default TicketTask;

/**
 {editing ? <input type='text' className={`w-100`} name={name} value={editLabel}
                                  onChange={(e) => setEditLabel(()=>e.target.value)}/>
                    : <><input type='checkbox' name={name} onChange={onChangeHandler} style={{marginTop: '2px'}}
                               checked={active} className={'me-2'}/> <span>{label}</span></>}
 */