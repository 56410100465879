/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 5/1/2023
 * for userx-support
 */

import React, {useEffect, useRef, useState} from 'react';
import {fetchCompanyContacts} from "../../_utilities/companyUtilities";
import {search} from "../../_utilities/reactUtils";

const TagPopup = ({show = true, ticket, agentList, clickHandler}) => {
    const [contacts, setContacts] = useState([]);
    const [contactMatch, setContactMatch] = useState(null);
    const [tagSearcherText, setTagSearcherText] = useState('');
    const tagSearcher = useRef(null);

    useEffect(() => {
        const matches = contacts.filter(c => `${c.first_name} ${c.last_name}`.substr(0, tagSearcherText.length).toLowerCase() === tagSearcherText.toLowerCase());
        if (matches.length > 0) {
            setContactMatch(() => matches[0].contact_id);
            const allListItems = document.querySelectorAll('.tag-popup-item');
            allListItems.forEach((item, index) => item.classList.remove('selected'));
            document.getElementById(`${matches[0].contact_id}`).classList.add('selected');
        }
    }, [tagSearcherText]);

    useEffect(() => {
        // get company contacts associated with ticket company
        try {
            fetchCompanyContacts(ticket.company_id).then(coContacts => {
                let list = [...coContacts, ...agentList];
                list = list.filter((ele, ind) => ind === list.findIndex(elem => elem.contact_id === ele.contact_id));
                setContacts(() => list.filter((ele, ind) => ind === list.findIndex(elem => elem.contact_id === ele.contact_id)));
                tagSearcher.current.focus();
            });
        } catch (err) {
            console.error('FAIL', err);
        }
    }, []);

    if (show) {
        return (
            <div id={`tag-popup`} className='d-flex flex-column'>
                <div className={`btn btn-danger  btn-sm w-100`} id={-1} key={`cancel-tag`}
                     onClick={(contact => clickHandler(contact))}>Cancel Tag
                </div>
                <input ref={tagSearcher} type='text' id='tag-searcher' value={tagSearcherText}
                       onChange={(e) => setTagSearcherText(e.target.value)}
                       onKeyUp={(e) => e.key === 'Enter' ? document.getElementById(`${contactMatch}`).click() : null}
                />
                {contacts.map(contact => <div id={`${contact.contact_id}`}
                                              key={`contact-${contact.contact_id}`}
                                              data-email={contact.email}
                                              data-name={`${contact.first_name} ${contact.last_name}`}
                                              className={`tag-popup-item btn btn-light`}
                                              onClick={(e) => clickHandler(e)}>
                    @{contact.first_name} {contact.last_name}</div>)}
            </div>
        );
    }
};

export default TagPopup;