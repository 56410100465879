/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import React, {useEffect, useState} from 'react';
import Badge from "react-bootstrap/Badge";
import {useNavigate} from "react-router";
import {TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../../system/defaults";
import useLocalStorage from "../../_hooks/useLocalStorage";
import {formatDate, shorten} from "../../_utilities/formattingUtils";
import Checkbox from "./formElements/Checkbox";
import SelectorInput from "./formElements/SelectorInput";

const TableBlock = ({title, items, classes = null, style = null}) => {
    const navigate = useNavigate();
    const [agentFilters,setAgentFilters] = useLocalStorage('savedAgentFilters');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [sortBy, setSortBy] = useLocalStorage('savedSortBy','priorityId');
    const [filters, setFilters] = useLocalStorage('savedFilters',{
        needinfo:      false,
        cancelled:     false,
        inprogress:    false,
        testing:       false,
        backlog:       true,
        readytolaunch: false,
        assigned:      true,
        companyFilter: '-1'
    });
    const [assignedOnly, setAssignedOnly] = useLocalStorage('true');

    useEffect(() => {
        if (items.length > 0) {
            // Extract unique company IDs
            const ticketCompanies = [...new Set(items
                .filter(item => item.company && item.company_id)  // Ensure company and company_id exist
                    .map(item => item.company_id)  // Map to the company ids
            )]
            .map(companyId => {
                // Find the first item that matches the company ID and has a defined company object
                const item = items.find(item => item.company && item.company_id === companyId);
                // Return company or "Unknown" if not found or if the company's name is missing
                return item && item.company ? {
                    ...item.company,
                    name: item.company.name || "Unknown"  // Replace missing or null name with "Unknown"
                } : { name: "Unknown" };
            })
            .sort((a, b) => a.name.localeCompare(b.name)); // Sort by company name

            setFilteredCompanies(ticketCompanies);
        }
    }, [items]);

    const handleFilterChange = (name, active) => {
        const updatedFilters = { ...filters, [name]: active };
        setFilters(updatedFilters);
    };
    const handleCompanyFilterChange = (e) => {
        const updatedFilters = {...filters, companyFilter: e.target.value};
        setFilters(updatedFilters);
    }
    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    }

    const rowClickHandler = (ticket) => {
        navigate(`/clients/${ticket.company_id}/tickets/${ticket.ticket_id}`);
    }

    const renderRow = (ticket) => {
        if(ticket.on_hold !== "0") return null;
        const priority = TICKET_PRIORITIES.find(i => i.id === parseInt(ticket.priority_id));
        const status = TICKET_STATUS.find(i => i.id === parseInt(ticket.status_id));
        const type = TICKET_TYPES.find(i => i.id === parseInt(ticket.type_id));

        const option = <div key={`ticket_${ticket.ticket_id}`} className={`d-flex align-items-start py-2 mb-2`}
                            style={{borderBottom: "1px solid #efefef"}}>
            <input type='checkbox' className={`mt-1 me-3`}/>
            <div className={`d-flex flex-column`} onClick={() => rowClickHandler(ticket)}>
                <div style={{fontSize: ".9em"}}>
                    <Badge className='me-1' bg={priority.color} title={priority.name}>{status.name}</Badge>
                    {type ? type.name : null} | Submitted: {formatDate(ticket.date_created)} | {ticket.date_due ?
                    <strong
                        className={`text-dark`}>Due: {formatDate(ticket.date_due)} | </strong> : null} {ticket.company ?
                    <a href={`/clients/${ticket.company.company_id}`}
                       style={{color: "#666"}}>{shorten(ticket.company.name, 30)}</a> : null}
                </div>
                <div style={{fontSize: "1.2em"}}>{ticket.title}</div>
            </div>
        </div>;

        if (!filters.companyFilter || filters.companyFilter === '-1') {
            return option;
        } else if (ticket.company_id === filters.companyFilter) {
            return option;
        }
    }

    return (
        <div className={`ticket-list h-100 bg-light rounded p-3 shadow-sm flex-grow-1 d-flex flex-column ${classes}`}
             style={style}>
            <div className={`d-flex justify-content-between flex-wrap`}>
                <h3 className={`me-3`} style={{minWidth: '200px'}}>{title}</h3>
                <div className={`flex-grow-1 d-flex flex-wrap align-items-center justify-content-between`}>
                    <select className={`flex-grow-1`}
                            style={{height: '30px', padding: '0px 10px'}}
                            value={filters.companyFilter ? filters.companyFilter : -1}
                            onChange={handleCompanyFilterChange}>
                        <option value={-1}>All Clients</option>
                        {filteredCompanies.map((company, index) => {
                            return <option key={`company_${index}`}
                                           value={company.company_id}>{shorten(company.name, 30)}</option>
                        })}
                    </select>
                </div>
            </div>

            <div className={`my-1 pb-3 d-flex flex-wrap align-items-center justify-content-start`}>
                <strong className={`me-3`}>Sort By:</strong>
                <select className={`flex-grow-1 me-4`} style={{maxWidth: "150px"}} value={sortBy}
                        onChange={handleSortChange}>
                    <option value={`priorityId`}>Priority</option>
                    <option value={`dateCreated`}>Date Created</option>
                    <option value={`dateDue`}>Date Due</option>
                    <option value={`company`}>Company</option>
                </select>
                <strong className={`me-3`}>Filters:</strong>
                <Checkbox name={`backlog`} label={TICKET_STATUS[0].name} classes={`mb-0 pb-0 me-3 mh-1`}
                          checked={filters.backlog} strikethrough={false}
                          changeHandler={active => handleFilterChange('backlog', active)}/>
                <Checkbox name={`assigned`} label={TICKET_STATUS[1].name} classes={`mb-0 pb-0 me-3 mh-1`}
                          checked={filters.assigned} strikethrough={false}
                          changeHandler={active => handleFilterChange('assigned', active)}/>
                <Checkbox name={`needinfo`} label={TICKET_STATUS[6].name} classes={`mb-0 pb-0 me-3 mh-1`}
                          checked={filters.needinfo} strikethrough={false}
                          changeHandler={active => handleFilterChange('needinfo', active)}/>
                <Checkbox name={`inprogress`} label={TICKET_STATUS[2].name} classes={`mb-0 pb-0 me-3`}
                          checked={filters.inprogress} strikethrough={false}
                          changeHandler={active => handleFilterChange('inprogress', active)}/>
                <Checkbox name={`testing`} label={TICKET_STATUS[3].name} classes={`mb-0 pb-0 me-3`}
                          checked={filters.testing} strikethrough={false}
                          changeHandler={active => handleFilterChange('testing', active)}/>
                <Checkbox name={`readytolaunch`} label={TICKET_STATUS[7].name} classes={`mb-0 pb-0 me-3`}
                          checked={filters.readytolaunch} strikethrough={false}
                          changeHandler={active => handleFilterChange('readytolaunch', active)}/>

            </div>
            <div className={`d-flex flex-column`}>
                {items && items.length > 0 ? items.filter(filterItems).sort(sortItems).map(item => renderRow(item)) : 'Take a break!'}
            </div>
        </div>
    );

    function filterItems(item) {
        const {backlog, assigned, needinfo, inprogress, testing, readytolaunch} = filters;
        if (!backlog && !assigned && !needinfo && !inprogress && !testing && !readytolaunch) {
            // If no filters are selected, show all items
            return true;
        }

        const statusId = parseInt(item.status_id, 10);

        return (backlog && statusId === TICKET_STATUS[0].id) ||
            (assigned && statusId === TICKET_STATUS[1].id) ||
            (needinfo && statusId === TICKET_STATUS[6].id) ||
            (inprogress && statusId === TICKET_STATUS[2].id) ||
            (testing && statusId === TICKET_STATUS[3].id) ||
            (readytolaunch && statusId === TICKET_STATUS[7].id);
    }

    function sortItems(a, b) {
        switch (sortBy) {
            case 'priorityId':
                return parseInt(a.priority_id, 10) - parseInt(b.priority_id, 10);
            case 'dateCreated':
                return compareDates(a.date_created, b.date_created);
            case 'dateDue':
                return compareDates(a.date_due, b.date_due);
            case 'company':
                return parseInt(a.company_id, 10) - parseInt(b.company_id, 10);
            default:
                return 0;
        }
    }

    function compareDates(date1, date2) {
        // Parse the dates and compare them
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        return d1 - d2; // For ascending order. Flip `d1` and `d2` for descending.
    }
}

export default TableBlock;