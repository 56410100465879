/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 */

import supportAPI from "../_apis/supportAPI";
import {fetchContact} from "./contactUtilities";

export const addCompany = async (company) => {
    const companyResponse = await supportAPI.post(`/company`, JSON.stringify(company));
    if (!companyResponse.data) {
        throw new Error('Company not created.');
    }
    return companyResponse.data;
}
export const updateCompany = async (company) => {
    const companyResponse = await supportAPI.put(`/company/${company.company_id}`, JSON.stringify(company));
    if(!companyResponse.data){
        throw new Error('Company not updated.');
    }
    return companyResponse.data;
}

export const getCompanyMeta = async (companyId, metaKey) => {
    try {
        const response = await supportAPI.get(`/company/${companyId}/meta`);
        if (response && response.data) {
            return response.data.find(meta => meta.meta_key === metaKey);
        }
    } catch (error) {
        console.error(`Failed to fetch metadata for company ID: ${companyId}`, error);
    }
    return null;
};

export const updateCompanyMeta = async (meta) => {
    console.log('companyUtilities::updateCompanymeta', meta);
    let metaResponse = null;

    // Fetch the existing metadata entry if the ID is not present
    if (!meta.id) {
        const existingMeta = await getCompanyMeta(meta.company_id, meta.meta_key);
        if (existingMeta) {
            meta.id = existingMeta.id; // Set the ID of the existing meta entry
            console.log('companyUtilities::updateCompanymeta::existingMetaFound', existingMeta);
        }
    }

    // Decide between updating or inserting based on whether ID is present
    if (meta.id) {
        console.log('companyUtilities::updateCompanymeta::update', meta);
        metaResponse = await supportAPI.put(`/company/${meta.company_id}/meta/${meta.id}`, JSON.stringify(meta));
    } else {
        console.log('companyUtilities::updateCompanymeta::insert', meta);
        metaResponse = await supportAPI.post(`/company/${meta.company_id}/meta`, JSON.stringify(meta));
    }

    if (!metaResponse || !metaResponse.data) {
        throw new Error('Company meta not updated');
    }
    return metaResponse.data;
};
export const addCompanyContact = async (companyContact) => {
    const companyContactResponse = await supportAPI.post(`/company/${companyContact.company_id}/contacts`, JSON.stringify(companyContact));
    if (!companyContactResponse.data) {
        throw new Error('Company Contact not created.');
    }
    return companyContactResponse.data;
}
export const updateCompanyContact = async(companyContact) => {
    const companyContactResponse = await supportAPI.put(`/company/${companyContact.company_id}/contacts/${companyContact.contact_id}`, JSON.stringify(companyContact));
    if (!companyContactResponse.data) {
        throw new Error('Company contact not updated.');
    }
    return companyContactResponse.data;
}

export const deleteCompanyContact = async(companyId, companyContactId) => {
    const companyContactResponse = await supportAPI.delete(`/company/${companyId}/contacts/${companyContactId}`);
    if (!companyContactResponse.data) {
        throw new Error('Company Contact not deleted.');
    }
    return companyContactResponse.data;
}

export const fetchCompanies = async () => {
    const companiesResponse = await supportAPI.get(`/company`);
    if(!companiesResponse.data){
        throw new Error('No companies found.');
    }
    return companiesResponse.data;
}

export const fetchCompany = async (companyId) => {
    const companyResponse = await supportAPI.get(`/company/${companyId}`);
    if (!companyResponse.data) {
        throw new Error('Company not found.')
    }
    return companyResponse.data;
}

export const urlExists = async (url) => {
    const companyResponse = await supportAPI.get(`/company//search?field=url&value=${url}`);
    if (!companyResponse.data || companyResponse.data.length === 0) {
        return false
    }
    return true;
}

export const fetchCompanyContacts = async (companyId) => {
    const companyContactResponse = await supportAPI.get(`/company/${companyId}/contacts`);
    if (!companyContactResponse.data) {
        throw new Error('Company contacts not found.')
    }
    return companyContactResponse.data;
}

export const fetchDefaultAgent = async (companyId) => {
    try {
        // Fetch the default agent metadata from the API
        const defaultAgentResponse = await supportAPI.get(`/company/${companyId}/meta/default_agent`);

        // Check if data is valid and an array
        const defaultAgentData = defaultAgentResponse.data;
        if (!Array.isArray(defaultAgentData) || defaultAgentData.length === 0) {
            console.error(`No default agent found for company ID: ${companyId}`);
            return null;
        }

        // Retrieve the agent's contact ID
        const agentId = defaultAgentData[0].meta_value;
        const metaId = defaultAgentData[0].id;

        // Fetch the agent details using the contact ID
        const agent = await fetchContact(agentId);
        if (!agent) {
            console.error(`No contact found for agent ID: ${agentId}`);
            return null;
        }
        agent.meta_id = metaId;
        return agent;
    } catch (error) {
        console.error(`Failed to fetch default agent for company ID: ${companyId}. Error: ${error.message}`);
        return null;
    }
};