/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../_contexts/UserContext";
import {useNavigate} from "react-router";
import supportAPI from "../_apis/supportAPI";
import {Link} from "react-router-dom";
import {search} from "../_utilities/reactUtils";
import {renderAlert} from "../_utilities/formattingUtils";
import {sendResetCode} from "../_utilities/contactUtilities";

const ForgotPassword = () => {
    const [error, setError] = useState(null);
    const [isSent, setIsSent] = useState(false);
    const [username, setUsername] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const navigate = useNavigate();

    const submitHandler = () => {
        console.log('ForgotPassword::submitHandler',username);
        setDisableButton(true);
        // get contactId
        search('contact',[`email="${username.trim()}"`], 'AND', null,true)
            .then(contact => {
                setError(null);
                console.log('contactId', contact.contact_id);
                if(!contact || contact.contact_id === undefined){
                    setError('Contact not found. Please try again.');
                    setDisableButton(()=>false);
                } else {
                    sendResetCode(contact.contact_id).then(response => setDisableButton(false));
                }
            });

    }

    return (
        <div className={`d-flex justify-content-center`}>
            <div className={`p-3 m-2 text-center`}>
                <h2>Forgot your password?</h2>
                <p>No problem. Enter the email address associated with your account and we'll send you a reset link.</p>
                {error ? <div className="py-2 px-3 m-3">{renderAlert(error, 'danger')}</div>:null}
                <input className={`form-control mx-auto mb-2`} style={{maxWidth: '200px'}}
                       type='text'
                       name='email'
                       placeholder={`joe@schmoe.com`}
                       value={username}
                       onChange={(e) => setUsername(e.target.value)}
                />
                <input className={`form-control mx-auto btn btn-success mb-3`} style={{maxWidth: '200px'}}
                       id='forgot-button'
                       type='button'
                       value={disableButton ? 'Sending...' : 'Send Reset Link'}
                       disabled={disableButton}
                       onClick={submitHandler}
                />
                <p>Not registered? <Link to={`/register`}>Create an account</Link>.</p>
            </div>
        </div>
    );
};

export default ForgotPassword;