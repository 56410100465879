/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/6/2022
 * for support.userx.co
 */

import axios from 'axios';

export default axios.create({
    baseURL: 'https://api.userx.co/support',
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'multipart/form-data'
    },
    params: {
        '20cccac8179819895e616d0268409af4': '475c6dd62f58a705d591d3a507006f50457709d1db8013a78856d6c210cf4be2'
    }
});