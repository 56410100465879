/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 4/22/2024
 * for userx-support
 */

import {createContext, useState, useContext} from "react";

export const DashboardContext = createContext();

// Provider component
export const DashboardProvider = ({ children }) => {
    const [isRefreshing, setIsRefreshing] = useState(true);
    const [selectedItems, setSelectedItems] = useState(new Set());

    // Add more state as needed

    const toggleRefresh = (value) => {
        setIsRefreshing(value);
    };

    const handleSelectItem = (item) => {
        setSelectedItems(prev => {
            const newSet = new Set(prev);
            if (newSet.has(item)) {
                newSet.delete(item);
            } else {
                newSet.add(item);
            }
            return newSet;
        });
    };

    // Provide the context value
    return (
        <DashboardContext.Provider value={{ isRefreshing, toggleRefresh, selectedItems, handleSelectItem }}>
            {children}
        </DashboardContext.Provider>
    );
};

// Custom hook to use the dashboard context
export const useDashboard = () => useContext(DashboardContext);