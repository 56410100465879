/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/21/2022
 * for support.userx.co
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import {convertDateToTimestamp, formatCurrency, formatDate, renderAlert} from "../_utilities/formattingUtils"
import {STEP, STEP_VALUE, TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../system/defaults";
import Badge from "react-bootstrap/Badge";
import {calculateTicketCost, fetchTicket, fetchTicketActivity, updateTicketField} from "../_utilities/ticketUtils";
import CompanyInfo from "./_ui/CompanyInfo";
import {UserContext} from "../_contexts/UserContext";
import {getUID} from "bootstrap/js/src/util";
import {useParams} from "react-router-dom";
import DatePicker from "react-widgets/DatePicker";
import {calculateBusinessDays, search} from "../_utilities/reactUtils";
import ActivityFeed from "./_ui/ActivityFeed";
import ReactMarkdown from 'react-markdown';
import useInterval from "../_hooks/useInterval";
import {addActivity} from "../_utilities/activityUtilities";
import supportAPI from "../_apis/supportAPI";
import MarkdownInput from "./_ui/formElements/MarkdownInput";
import {addNotification} from "../_utilities/notificationUtilities";
import Attachments from "./_ui/Attachments";
import TicketTasks from "./tickets/TicketTasks";
import SelectorInput from "./_ui/formElements/SelectorInput";
import Checkbox from "./_ui/formElements/Checkbox";
import {sendActivity, sendNewTicket, sendNote} from "../_utilities/contactUtilities";
import {useNavigate} from "react-router";

const Ticket = () => {
    const params = useParams();
    const navigate = useNavigate();
    const {userContext} = useContext(UserContext);
    const [ticket, setTicket] = useState(null);
    const [estimateUpdate, setEstimateUpdate] = useState(false);
    const [estimateHour, setEstimateHour] = useState(0);
    const [estimateMin, setEstimateMin] = useState(0);
    const [type, setType] = useState(null);
    const [priority, setPriority] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [status, setStatus] = useState(null);
    const [agentList, setAgentList] = useState(null);
    const [ticketCost, setTicketCost] = useState('$0');
    const [error, setError] = useState(null);
    const [ticketTitle, setTicketTitle] = useState('');
    const [editTitle, setEditTitle] = useState(false);
    const [ticketDesc, setTicketDesc] = useState('');
    const [editDesc, setEditDesc] = useState(false);
    const [dateDue, setDateDue] = useState(null);
    const [refreshing, setRefreshing] = useState(true);

    useInterval(() => {
        refresh();
    }, refreshing ? 1000 : null);

    useEffect(() => {
        if (ticket) {
            setType(() => TICKET_TYPES.find(item => parseInt(item.id) === parseInt(ticket.type_id)));
            setPriority(() => TICKET_PRIORITIES.find(item => parseInt(item.id) === parseInt(ticket.priority_id)));
            setStatus(() => TICKET_STATUS.find(item => parseInt(item.id) === parseInt(ticket.status_id)));
            setDateDue(()=>ticket.date_due ? new Date(ticket.date_due) : null);
            setTicketTitle(()=>ticket.title);
            setTicketDesc(()=>ticket.description);
            if (agentList && agentList.find) {
                setAssignee(() => agentList.find(item => parseInt(item.contact_id) === parseInt(ticket.assignee_id)));
            }
        }
    }, [ticket, agentList]);

    const ticketChangeHandler = (field, value) => {
        console.log('Ticket::ticketChangeHandler',field,value);
        try {
            let activityMsg = '';
            let notificationMsg = '';
            const updatedTicket = {
                contact_id: ticket.contact_id,
                company_id: ticket.company_id,
                title: ticket.title,
                description: ticket.description ? ticket.description : 'null'
            };
            updatedTicket[field] = value;

            switch(field){
                case "status_id":
                    switch(parseInt(value)){
                        case 2:
                            updatedTicket.date_assigned = convertDateToTimestamp(new Date());
                            break;
                        case 5:
                            updatedTicket.date_completed = convertDateToTimestamp(new Date());
                            break;
                        default:
                    }
                    break;
                case "assignee_id":
                    console.log("ticket::changeHandler::assignee_id",ticket, value);
                    if(value === 'null'){
                        updatedTicket.status_id = '1';
                    } else if(parseInt(ticket.status_id) === 1){
                        console.log("ASSIGNEE_ID SET TO BACKLOG TICKET");
                        updatedTicket.status_id = '2';
                    }
                    break;
                case "on_hold":
                    updatedTicket.on_hold = value.toString();
                    break;
                case "estimate_time":
                    updatedTicket.estimate_approved = '-1';
                    break;
                case "estimate_approved":
                    updatedTicket.estimate_approved = '1';
                    break;
                case "date_due":
                    updatedTicket.date_due = value.toString();
            }

            supportAPI.put(`/ticket/${ticket.ticket_id}`, JSON.stringify(updatedTicket)).then(ticketResponse => {

                switch (field) {
                    case 'type_id':
                        activityMsg = `Type changed to ${TICKET_TYPES.find(item => item.id === parseInt(value)).name}.`;
                        break;
                    case 'priority_id':
                        const priority = TICKET_PRIORITIES.find(item => item.id === parseInt(value))
                        activityMsg = `Priority changed${priority ? ` to ${priority.name}.` : null}.`;
                        notificationMsg = `Ticket #${ticketResponse.data.ticket_id} priority changed to ${priority.name}.`
                        break;
                    case 'date_due':
                        activityMsg = `Due Date changed to ${value}.`;
                        notificationMsg = `Ticket #${ticketResponse.data.ticket_id} due date changed to ${value}.`
                        break;
                    case 'status_id':
                        const status = TICKET_STATUS.find(item => item.id === parseInt(value))
                        activityMsg = `Status changed${status ? ` to ${status.name}` : null}.`;
                        notificationMsg = `Ticket #${ticketResponse.data.ticket_id} status changed to ${status.name}.`
                        break;
                    case 'assignee_id':
                        if (value !== 'null') {
                            const assignee = agentList.find(item => item.contact_id === value);
                            if (assignee) {
                                addNotification({
                                    contact_id: assignee.contact_id,
                                    ticket_id: ticket.ticket_id,
                                    note: `You have been assigned to ticket ${ticket.ticket_id}.`
                                }).then(r => {
                                    console.log('Notification sent to agent', assignee);
                                    sendNote(assignee.contact_id,`User X Support: Assigned Task`,`You have been assigned to ticket ${ticket.ticket_id}.`).then(r =>{
                                        console.log('Email sent to agent', assignee);
                                    });
                                });

                            }
                            activityMsg = `Assigned ${undefined !== assignee ? `${assignee.first_name} ${assignee.last_name}.` : `an agent.`}`;
                            notificationMsg = `Ticket #${ticketResponse.data.ticket_id} assigned to ${assignee.first_name} ${assignee.last_name}.`
                        } else {
                            activityMsg = `Ticket unassigned and status changed to Backlog.`;
                            notificationMsg = `Ticket #${ticketResponse.data.ticket_id} unassigned and status changed to Backlog.`
                        }
                        break;
                    case 'estimated_time':
                        const estimatedCost = formatCurrency(parseInt(value) * STEP_VALUE);
                        activityMsg = `Estimate updated to ${estimatedCost}.`;
                        notificationMsg = `Ticket #${ticketResponse.data.ticket_id} estimate changed to ${estimatedCost}. APPROVAL REQUIRED`;
                        break;
                    case 'estimate_approved':
                        activityMsg = `Estimate approved.`;
                        notificationMsg = `Ticket #${ticketResponse.data.ticket_id} estimate has been approved.`;
                        break;

                    default:
                }
                if (activityMsg !== '') {
                    addActivity({
                        contact_id: userContext.contact.contact_id,
                        ticket_id: ticketResponse.data.ticket_id,
                        note: activityMsg
                    }).then(response => {
                        // if there is a notification message to post
                        if (notificationMsg !== '') {
                            // if current user is contact for ticket skip contact notification
                            if (parseInt(ticketResponse.data.contact_id) !== parseInt(userContext.contact.contact_id)) {
                                addNotification({
                                    contact_id: ticketResponse.data.contact_id,
                                    ticket_id: ticketResponse.data.ticket_id,
                                    note: notificationMsg
                                });
                            }
                            // if current user is assignee for ticket skip assignee notification
                            if (ticket.assignee_id && parseInt(ticketResponse.data.assignee_id) !== parseInt(userContext.contact.contact_id)) {
                                addNotification({
                                    contact_id: ticketResponse.data.assignee_id,
                                    ticket_id: ticketResponse.data.ticket_id,
                                    note: notificationMsg
                                });
                            }
                        }
                    });
                }

                refresh();

                if(field === 'status_id' && (value === "5" || value === "6")){
                    navigate(-1);
                }
            });
        } catch (err) {
            console.error('ticketChangeHandler FAIL', err);
            setError(err.message());
        }
    }

    return error ? <div className="py-2 px-3 m-3">{renderAlert(error, 'danger')}</div> : <>
        {ticket ? <div id='ticket-wrapper' className="flex-grow-1">
            <div id='ticket-body' className="py-2 px-3 m-3 d-flex align-items-stretch">
                <h2 className="d-flex align-items-start">
                    {priority && status ?
                        <Badge bg={priority.color} style={{marginTop: '8px', fontSize: '12px'}}
                               title={priority.name}>{status.name}</Badge> : null}
                    {ticket ? <>
                        {editTitle === true ? <>
                            <input type={`text`} value={ticketTitle} className={`mx-3 px-3 flex-grow-1`} onChange={e => setTicketTitle(e.target.value)} />
                            <button className={`btn btn-success btn-sm mt-1`}
                                                        onClick={()=>{
                                                            ticketChangeHandler('title',ticketTitle);
                                                            setRefreshing(true);
                                                            setEditTitle(false)
                                                        }}
                        >Update Title</button>
                            </> : <>
                        <span
                                className='px-3 flex-grow-1'>{ticket && ticket.title ? ticket.title : `Ticket #${params.id}`}</span>
                        <button className={`btn btn-success btn-sm mt-1 align-self-end`}
                            onClick={(e)=>{
                                setTicketTitle(e.target.value);
                                setRefreshing(false);
                                setEditTitle(true)
                            }}>Edit Title</button>
                        </>}
                    </> : null}

                </h2>
                {ticket ? <>
                {editDesc === true ? <>
                        <MarkdownInput value={ticketDesc} changeHandler={(value) => setTicketDesc(() => value)}
                                       height='300px'/>
                        <div className={`d-flex justify-content-end`}>
                            <button className={`btn btn-success btn-sm mt-3 me-3`}
                                    onClick={() => {
                                        ticketChangeHandler('description', ticketDesc);
                                        setRefreshing(() => true);
                                        setEditDesc(() => !editDesc)
                                    }}>Update Description
                            </button>
                            <button className={`btn btn-danger btn-sm mt-3`}
                                    onClick={() => {
                                        setRefreshing(() => true);
                                        setEditDesc(() => !editDesc)
                                    }}>Cancel
                            </button>
                        </div>
                    </> : <>
                        <ReactMarkdown>{`**${type ? type.name : null}** - ${ticket.description ? ticket.description : "Click the Edit Description button to add a description."}`}</ReactMarkdown>

                        <div className={`d-flex justify-content-end`}>
                            <button className={`btn btn-success btn-sm`}
                                    onClick={() => {
                                        setRefreshing(() => false);
                                        setEditDesc(() => !editDesc)
                                    }}>Edit Description
                            </button>
                        </div>
                    </>}
                </> : null}
                <hr className={`pb-1`}/>
                <div className={`row`}>
                    <TicketTasks ticket={ticket} classes={`col-xs-12 col-sm-5`}/>
                    <Attachments targetType="ticket" target={ticket ? ticket : null} classes={`col-xs-12 col-sm-5`}/>
                </div>
                <hr className={`pb-1`}/>
                <div className='flex-grow-1'>
                    {ticket ? <ActivityFeed ticket={ticket} agentList={agentList}
                                            openHandler={open => setRefreshing(() => !open)}
                                            changeHandler={activityChangeHandler}/> : null}
                </div>
            </div>

            <div id='ticket-aside' className="py-2 px-3 bg-secondary bg-opacity-10">
                {ticket ? <CompanyInfo company_id={ticket.company_id} contact_id={ticket.contact_id}/> : null}

                {(ticket && ticket.estimate_required === "1") ? <>
                    {userContext.contact.group_id === "1" ? <>
                        Set Estimate:
                        {!estimateUpdate ? <>
                            <div className="mb-3 d-flex">
                                <input type='text' className='form-control me-2'
                                       value={formatCurrency(ticket.estimated_time * STEP_VALUE)} disabled/>
                                <button className='btn btn-warning'
                                        onClick={() => setEstimateUpdate(() => true)}>Update
                                </button>
                            </div>
                        </> : <>
                            <div className="mb-3 d-flex justify-content-between">
                                <label htmlFor='estimate-hour' className='me-2'>
                                    <input type='number' id='estimate-hour' name='estimate-hour'
                                           className='form-control'
                                           placeholder='hour' value={estimateHour}
                                           onChange={handleEstimateChange}
                                           min={0}
                                           max={8}/></label>
                                <label htmlFor='estimate-hour' className='me-2'>
                                    <input type='number' id='estimate-min' name='estimate-min'
                                           className='form-control'
                                           placeholder='min' value={estimateMin} onChange={handleEstimateChange}
                                           min={0}
                                           max={54} step={6}/></label>
                                <button className='btn btn-success flex-grow-1'
                                        onClick={handleEstimateUpdate}>Save
                                </button>
                            </div>
                        </>}
                    </> : <>
                        Estimated / Actual Cost:
                        {ticket.estimated_time !== "0" ? <>
                            <div className="mb-3 d-flex">
                                <input type='text' className='form-control me-2'
                                       value={`${formatCurrency(ticket.estimated_time * STEP_VALUE)} / ${ticketCost}`}
                                       disabled/>
                                {ticket.estimate_approved === "-1" ?
                                    <button className='btn btn-success'
                                            onClick={() => ticketChangeHandler('estimate_approved', '1')}>Accept
                                    </button> : null}
                            </div>
                        </> : <input type='text' className='form-control me-2'
                                     value={`Pending estimate...`} disabled/>}
                    </>}
                </> : null}
                {ticket && ticket.date_due ?
                    <div className={`text-center mb-3 h3`}>Due Date: {formatDate(ticket.date_due)}</div> : null}

                <div className={`my-3`}>
                    {ticket && ticket.status_id && ticket.status_id === "7" ?
                        <button className={`btn btn-success w-100 mb-1`}
                                onClick={(e) => window.confirm("Please note: this will move the ticket back to the Assigned list to be worked on. Please remember to add comments regarding needed information.") ? ticketChangeHandler('status_id', "2") : null}>Info Provided</button> : null}
                    {ticket && ticket.status_id && ticket.status_id === "4" ?
                        <button className={`btn btn-warning w-100 mb-1`}
                                onClick={(e) => window.confirm("Please note: this will move the ticket back to the Assigned list to be worked on. Please remember to add comments regarding additional work.") ? ticketChangeHandler('status_id', "2") : null}>Needs
                            More Work</button> : null}
                    {ticket && ticket.status_id && ticket.status_id === "4" ?
                        <button className={`btn btn-warning w-100 mb-1`}
                                onClick={(e) => window.confirm("Please note: this will move the ticket back to the Assigned list to be launched.") ? ticketChangeHandler('status_id', "8") : null}>Ready To Launch</button> : null}
                    {ticket && ticket.status_id && ticket.status_id === "4" ?
                        <button className={`btn btn-success w-100 mb-1`}
                                onClick={(e) => window.confirm("Please note: this will remove the ticket from your list. Continue?") ? ticketChangeHandler('status_id', "5") : null}>Mark
                            Complete</button> : null}
                    {ticket && ticket.status_id && parseInt(ticket.status_id) <= 4 ?
                        <button className={`btn btn-danger w-100`}
                                onClick={(e) => window.confirm("Please note: you will still be charged form time spent on this ticket. Continue?") ? ticketChangeHandler('status_id', "6") : null}>Cancel
                            Ticket
                        </button> : null}
                    {ticket && ticket.status_id && ticket.status_id === "5" ?
                        <button className={`btn btn-warning w-100`}
                                onClick={(e) => ticketChangeHandler('status_id', "1")}>Reopen Ticket
                        </button> : null}

                </div>

                Type:
                <SelectorInput inputClass={`mb-3`}
                               inputName={`type_id`}
                               inputValue={ticket && ticket.type_id ? ticket.type_id : ''}
                               inputOptions={TICKET_TYPES.map(item => {
                                   return {label: item.name, value: item.id}
                               })}
                               onOpen={(opened) => setRefreshing(() => !opened)}
                               onChange={(value) => ticketChangeHandler('type_id', value)}
                />

                Priority:
                <SelectorInput inputClass={`mb-3`}
                               inputName={`priority_id`}
                               inputValue={ticket && ticket.priority_id ? ticket.priority_id : ''}
                               inputOptions={TICKET_PRIORITIES.map(item => {
                                   return {label: item.name, value: item.id}
                               })}
                               onOpen={(opened) => setRefreshing(() => !opened)}
                               onChange={(value) => ticketChangeHandler('priority_id', value)}
                />

                Due Date: {ticket.date_due}
                <DatePicker id='ticket-due-date' className='datepicker mb-3'
                            defaultValue={dateDue}
                            dropUp={true}
                            placeholder={'mm/dd/yyyy'}
                            onChange={handleDateChange}
                />
                {/*<DatePicker id='ticket-due-date' className={`mb-3`}*/}
                {/*            dropUp={true}*/}
                {/*            placeholder={'mm/dd/yyyy'}*/}
                {/*            defaultValue={ticket && ticket.date_due ? new Date(ticket.date_due) : ''}*/}
                {/*            valueFormat={{dateStyle: "medium"}}*/}
                {/*            onFocus={() => setRefreshing(false)}*/}
                {/*            onBlur={() => setRefreshing(true)}*/}
                {/*            onChange={handleDateChange}*/}
                {/*/>*/}

                <div className={`d-flex justify-content-between`}>
                    <span>Status:</span>
                    <Checkbox name={`on_hold`}
                              label={`On Hold`}
                              checked={parseInt(ticket.on_hold) > 0 ? true : false}
                              changeHandler={(value) => ticketChangeHandler('on_hold', value ? 1 : 0)}
                    />
                </div>
                {parseInt(userContext.contact.group_id) <= 4 && agentList ?
                    <SelectorInput inputClass={`mb-3`}
                                   inputName={`status_id`}
                                   inputValue={ticket && ticket.status_id ? ticket.status_id : ''}
                                   inputOptions={TICKET_STATUS.map(item => {
                                       return {label: item.name, value: item.id}
                                   })}
                                   onOpen={(opened) => setRefreshing(() => !opened)}
                                   onChange={(value) => ticketChangeHandler('status_id', value)}
                    /> : <input type='text' disabled
                                value={ticket && ticket.status_id ? TICKET_STATUS[parseInt(ticket.status_id) - 1].name : ''}
                                className='mb-3 form-control'/>}


                Assigned Agent:
                {parseInt(userContext.contact.group_id) <= 4 && agentList ?
                    <SelectorInput inputClass={`mb-3`}
                                   inputInstructions={`Assign to...`}
                                   inputName={`assignee_id`}
                                   inputValue={ticket && ticket.assignee_id ? ticket.assignee_id : ''}
                                   inputOptions={agentList && agentList.map ? agentList.map(item => {
                                       return {label: `${item.first_name} ${item.last_name}`, value: item.contact_id}
                                   }) : null}
                                   onOpen={(opened) => setRefreshing(() => !opened)}
                                   onChange={(value) => ticketChangeHandler('assignee_id', value)}
                    /> : <input type='text' disabled
                                value={assignee ? `${assignee.first_name} ${assignee.last_name}` : 'Unassigned'}
                                className='mb-3 form-control'/>}

                {ticketCost && (userContext.group_id === "5" || userContext.group_id === "1") ? <>
                    Current Ticket Value:<br/>
                    <span style={{fontSize: '2rem'}}>{ticketCost}</span>
                </> : null}
            </div>
        </div> : <div className='py-2 px-3 m-3 w-75'><Badge bg='secondary' className='my-2'
                                                            style={{fontSize: '12px'}}>Loading
            Ticket data</Badge></div>}
    </>;

    function activityChangeHandler() {
        if (ticket) calculateTicketCost(ticket).then(cost => setTicketCost(cost));
    }

    function handleEstimateChange() {
        setEstimateHour(document.getElementById('estimate-hour').value);
        setEstimateMin(document.getElementById('estimate-min').value);
    }

    function handleEstimateUpdate() {
        let estimatedTime = ((estimateHour * 60) / STEP) + (estimateMin / STEP);
        if (estimatedTime < 0) {
            estimatedTime = 0;
        }
        setEstimateUpdate(() => false);
        ticketChangeHandler('estimated_time', estimatedTime.toString());
    }

    function handleDateChange(value) {
        // console.log('handleDateChange',value);
        if (value instanceof Date) {
            value.setHours(16, 0, 0, 0);
            const timestamp = convertDateToTimestamp(value);
            setDateDue(timestamp);
            ticketChangeHandler('date_due', timestamp);
        } else {
            if (value === null) {
                ticketChangeHandler('date_due', null);
                setDateDue(()=>null);
            } else {
                const today = new Date();
                const threeBusinessDaysFromNow = calculateBusinessDays(today, 3);
                threeBusinessDaysFromNow.setHours(16, 0, 0, 0);
                const timestamp = convertDateToTimestamp(calculateBusinessDays(threeBusinessDaysFromNow, 3));
                setDateDue(timestamp);
                ticketChangeHandler('date_due', timestamp);
            }
        }
    };

    function refresh() {
        try {
            if (refreshing) {
                fetchTicket(params.ticketId).then(t => {
                    if (t.estimate_required === '1' && t.estimated_time === '0') {
                        setEstimateUpdate(() => true);
                    }
                    setTicket(() => t);
                    setTicketDesc(() => t.description);
                    setDateDue(() => new Date(t.date_due));
                    calculateTicketCost(t).then(c => {
                        setTicketCost(() => c)
                    });
                });
                if (!agentList) {
                    search('contact', [`group_id IN (1,2,3,4)`]).then(response => {
                        // console.log('Ticket::refresh', 'agentList', response);
                        setAgentList(response)
                    });
                }
            }
        } catch (err) {
            console.error('Ticket FAIL', err);
        }
    }
}


export default Ticket;