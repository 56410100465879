/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 3/3/2023
 * for userx-support
 */

import React from 'react';

const Agreement = () => {
    return (
        <div id='agreement-wrapper' className="p-3">
            <h2>Maintenance Agreement</h2>
            <p>This Service Agreement (this “Agreement”) is made effective immediately by and between your company and
                User X, LLC, of Chesapeake, VA. In this Agreement, the party who is contracting to receive the services
                shall be referred to as “Client”, the party who will be providing the services shall be referred to as
                “User X”, and the services provided shall be referred to as “Services”.
            </p>
            <p className="c8 c12"></p><p className="c1">WHEREAS, User X possesses technical expertise in the field
            of computer programming and, in particular, the creation and deployment of website technology; and</p>
            <p className="c1">WHEREAS Client desires to engage User X, and User X accepts the
                engagement, to maintain a World Wide Web site and application (“Projects”) in accordance with terms and
                conditions set forth in this Agreement.
            </p>
            <p className="c1">NOW, THEREFORE, in consideration of the mutual covenants and
                agreements set forth herein, Client and User X agree as follows:
            </p>
            <h3 className="c9" id="h.kv8vnwgzius4">DESCRIPTION OF MAINTENANCE</h3>
            <p>User X will provide the following services connected with the maintenance of the Website (collectively,
                the
                “Services”): Strategic Planning, Graphic Design, Web Design, and Web Development.</p>
            <h3 className="c5" id="h.rlkxjdu31qm9">SERVICES INCLUDE:</h3>
            <ol start="1">
                <li className="c4 li-bullet-0">Any and all updates, additions, and/or deletions to the Client website.
                </li>
                <li className="c4 li-bullet-0">Graphics, animations, and/or media elements for any purpose.
                </li>
                <li className="c4 li-bullet-0">Monthly check and install updates for WordPress, plugins, or other
                    applications that are utilized on the website.
                </li>
                <li className="c6 li-bullet-0">Monitor for and apply any Urgent/critical updates including cyber
                    security updates
                </li>
            </ol>
            <h3 className="c5" id="h.fjpk7qvu1h0e">ESTIMATES FOR MAINTENANCE REQUESTS</h3>
            <p>User X will evaluate the maintenance requests added to the User X Support site&nbsp;and an estimate will be
            attached to each task if requested. The Client will have the opportunity to approve or reject the estimate
            by 1) clicking the approve button or 2) clicking the cancel button.</p>
            <h3 className="c5" id="h.r2aw70sa1kdm">PROCESS FOR ADDING MAINTENANCE REQUESTS</h3>
            <p>The User X Support site will be used to add/manage maintenance requests using the following steps:</p>
                <ol start="1">
                    <li className="c4 li-bullet-0">The Client and/or User X will add a new maintenance request, or
                        ticket, to the Backlog.
                    </li>
                    <li className="c4 li-bullet-0">User X will add an estimated cost to the new ticket.
                    </li>
                    <li className="c4 li-bullet-0">The Client will either approve or reject the estimate.
                    </li>
                    <li className="c4 li-bullet-0">If approved, User X will assign an agent to do the work.
                    </li>
                    <li className="c4 li-bullet-0">The Client will test work and determine if the task is complete or
                        needs more work.
                    </li>
                    <li className="c4 li-bullet-0">If more work is needed, the assigned agent will continue work and the
                        process will go back to step 5.
                    </li>
                    <li className="c4 li-bullet-0">If the task is complete the Client will mark the ticket as completed.
                    </li>
                    <li className="c4 li-bullet-0">Rejected estimates may be renegotiated between UserX and Client to
                        meet the time, schedule, and cost as appropriate.
                    </li>
                </ol>
                <h3 className="c9" id="h.eerer0bsxc6z">PROJECTS WITH SCOPE</h3><p
                >Services that require a more robust scope of work will be considered a new project and will require a
                    new scope of work, estimate, and development schedule. Rates and terms of this Maintenance Agreement will
                    apply to projects. It is intended to provide for rapid development and execution of projects to the
                    benefit of both parties.
                </p><p>If either party disagrees with these terms for any project, prior to the commencement of work on
                    the project, then a new contract agreement will be developed and executed for the work.
                </p><h3 className="c9" id="h.i5787zodrido">PAYMENT FOR SERVICES</h3><p
                >In consideration of the services to be performed by User X, Client agrees to compensate User X for the
                    services rendered as follows:<br/>
                </p>
                <ul className="c11 lst-kix_8kwxty3owpvq-0 start">
                    <li className="c4 li-bullet-0">User X’s fees for the services specified in the Description of
                        Services, above, and for any additional services, will be charged at User X’s standard hourly
                        rate of $100.00 per hour for Services.
                    </li>
                    <li className="c4 li-bullet-0">Any additional services not specified in the Description of Services,
                        above, will be charged to the Client on an hourly rate basis at User X’s standard hourly rate of
                        $100.00 per hour.
                    </li>
                    <li className="c4 li-bullet-0">Any meetings to discuss work details, estimates, and/or progress will
                        be charged to the Client at an hourly rate of $50.00 per hour.
                    </li>
                </ul>
                <h3 className="c9" id="h.toj0301a9lba">PAYMENT SCHEDULE</h3><p
                >The Client understands and agrees to the payment terms listed in this Agreement.
                </p>
                <ol className="c11 lst-kix_jduyy834lslh-0 start" start="1">
                    <li className="c4 li-bullet-0">Support tickets will be submitted to the User X Support website.
                    </li>
                    <li className="c4 li-bullet-0">Any time spent on tickets will be detailed in a monthly invoice and
                        sent out at the end of each month.
                    </li>
                    <li className="c4 li-bullet-0">The Client agrees to pay these monthly invoices within the first 14
                        days of each month.
                    </li>
                </ol>
                <h3 className="c9" id="h.dgin0btm5brd">TERM/TERMINATION</h3><p
                >This agreement will be ongoing until the Client or User X terminates the agreement.
                </p><h3 className="c9" id="h.j0mrbziv4xzt">RELATIONSHIP OF PARTIES</h3>
                <p>It is understood by the parties that User X is an independent contractor with respect to the Client
                    and not an employee of the Client. The Client will not provide fringe benefits, including health
                    insurance benefits, paid vacation, or any other employee benefit, for the benefit of User X.
                </p><h3 className="c9" id="h.4mtewsit8h3j">WORK PRODUCT OWNERSHIP</h3><p
                >Any copyrightable works, ideas, discoveries, inventions, patents, products, or other information
                    (collectively, the “Work Product”) developed in whole or in part by User X in connection with the
                    Services shall be the exclusive property of User X. Upon request, Client shall sign all documents
                    necessary to confirm or perfect the exclusive ownership of User X to the Work Product.
                </p>
            <p>UserX will grant a perpetual free license for the Client's use of the Website or other applications developed under this agreement in the Development Environment and/or the Production site. The license shall include the use of the work product for the client's sole use in its mission including the ability to upgrade or modify non-copyrighted products. The site shall become the property of the Client upon the termination of this agreement. </p>
            <h3>LAWS AFFECTING ELECTRONIC COMMERCE</h3>
                <p>Client agrees that Client is solely responsible for complying with laws, taxes, and tariffs that
                    governments enact and fix from time to time in connection with Internet electronic commerce, and
                    shall indemnify, hold harmless, protect, and defend User X and its subcontractors from any cost,
                    claim, suit, penalty, tar, or tariff, including attorneys’ fees, costs, and expenses, arising from
                    Client’s exercise of Internet electronic commerce.
                </p><h3>CONFIDENTIALITY</h3>
                <p>User X will not at any time or in any manner, either directly or indirectly, use for the personal
                    benefit of User X, or divulge, disclose, or communicate in any manner any information that is
                    proprietary to Client. User X will protect such information and treat it as strictly confidential.
                    This provision shall continue to be effective after the termination of this Agreement. Upon
                    termination of this Agreement, User X will return to Client all code, records, notes, documentation,
                    and other items that were used, created, or controlled by User X during the term of this Agreement.
                </p><h3 className="c9" id="h.t0pmbnytapis">INJURIES</h3><p
                >User X acknowledges User X’s obligation to obtain appropriate insurance coverage for the benefit of
                    User X (and User X’s employees, if any). User X waives any rights to recovery from Client for any
                    injuries that User X (and/or User X’s employees) may sustain while performing services under this
                    Agreement and that is a result of the negligence of User X or User X’s employees.
                </p><h3 className="c9" id="h.e9dmilvgv1pj">EMPLOYEES</h3><p
                >User X’s employees, if any, who perform services for the Client under this Agreement shall also be
                    bound by the provisions of this Agreement. At the request of the Client, User X shall provide
                    adequate evidence that such persons are User X’s employees.
                </p><h3 className="c9" id="h.lcgfp76mq0">ASSIGNMENT</h3><p
                >User X’s obligations under this Agreement may not be assigned or transferred to any other person, firm,
                    or corporation without the Client's prior written consent.
                </p><h3 className="c9" id="h.ywazcqz9mz0k">WARRANTY/GUARANTEE</h3><p
                >User X provides a limited warranty on all work provided for up to 30 days after launch or updates to
                    existing products. Bugs found within this warranty period will be corrected free of charge. However,
                    bugs found after this warranty period will be subject to rules set forth in the Payment of Services
                    section.
                </p><h3 className="c9" id="h.k15cacsy1suh">ENTIRE AGREEMENT</h3><p
                >This Agreement contains the entire agreement of the parties, and there are no other promises or
                    conditions in any other agreement whether oral or written.
                </p><h3 className="c9" id="h.wp9a25iyg4mt">SEVERABILITY</h3><p
                >If any provision of this Agreement shall be held to be invalid or unenforceable for any reason, the
                    remaining provisions shall continue to be valid and enforceable. If a court finds that any provision
                    of this Agreement is invalid or unenforceable, but that by limiting such provision it would become
                    valid and enforceable, then such provision shall be deemed to be written, construed, and enforced as
                    so limited.
                </p><h3 className="c9" id="h.l39e3o5f5tp">AMENDMENT</h3><p
                >No amendment, waiver, or discharge of any provision of this Agreement shall be effective against Client
                    or User X without the written consent of both Client and User X.
                </p><h3 className="c9" id="h.tx6812el39mt">NOTICES</h3><p
                >Any notice required to be given pursuant to this Agreement shall be in writing and mailed by certified
                    or registered mail, to the addresses mentioned above.
                </p><h3 className="c9" id="h.lzai261zgnnr">APPLICABLE LAW</h3><p
                >
                    This Agreement shall be governed by the laws of the State of Virginia.</p>
        </div>
);
};

export default Agreement;