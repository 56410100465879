/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
import {sortObjects} from "./reactUtils";
import supportAPI from "../_apis/supportAPI";

/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/27/2022
 * for userx-support
 */

export const addActivity = async (activity) => {
        const activityResult = await supportAPI.post(`/activity`, JSON.stringify(activity));
        if (!activityResult.data) {
            throw new Error('Activity not created.');
        }
        return activityResult.data;
}

export const fetchActivityByTicket = async (ticket_id) => {
        if (!ticket_id || ticket_id <= 0) {
            throw new Error('Input out of range.');
        }
        const activityResponse = await supportAPI.post(`/search/activity`, JSON.stringify({ticket_id: ticket_id}));
        if (!activityResponse.data) {
            throw new Error('No Activity found.');
        }
        return sortObjects(activityResponse.data, 'date_created', 'DESC');
}