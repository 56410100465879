/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/23/2022
 * for support.userx.co
 */

import "react-widgets/styles.css";
import React, {useContext, useEffect, useState} from 'react';
import {TICKET_PRIORITIES, TICKET_TYPES} from "../../../system/defaults";
import {convertDateToTimestamp, formatDate, renderAlert} from "../../../_utilities/formattingUtils";
import DatePicker from "react-widgets/DatePicker";
import {addTicket, assignTicket} from "../../../_utilities/ticketUtils";
import {UserContext} from "../../../_contexts/UserContext";
import {addActivity} from "../../../_utilities/activityUtilities";
import MarkdownInput from "./MarkdownInput";
import {useParams} from "react-router";
import {calculateBusinessDays} from "../../../_utilities/reactUtils";
import {fetchCompanyContacts, fetchDefaultAgent} from "../../../_utilities/companyUtilities";

const AddTicketForm = ({companyId, submitHandler}) => {
    const params = useParams();
    const {userContext} = useContext(UserContext);

    if (!userContext || !userContext.contact) {
        window.location.href = '/login';
    }

    const [errorMsg, setErrorMsg] = useState('');
    const [formProps, setFormProps] = useState({
        contact_id:        userContext.contact.contact_id,
        company_id:        userContext.contact.group_id === "1" && params.clientId ? params.clientId : companyId ? companyId : userContext.contact.company[0].company_id,
        assignee_id:       'null',
        type_id:           'null',
        status_id:         '1',
        priority_id:       'null',
        title:             'null',
        description:       'null',
        date_due:          'null',
        estimate_required: "0"
    });
    const [suggestedDate, setSuggestedDate] = useState(null);

    useEffect(() => {
        // get primary contact if company different than userContext
        if (parseInt(userContext.contact.group_id) <= 3 && (params.clientId || companyId)) {
            const company_id = params.clientId ? params.clientId : companyId ? companyId : userContext.contact.company[0].company_id;
            fetchCompanyContacts(company_id).then(contacts => {
                setFormProps(()=>({...formProps, contact_id: contacts[0].contact_id}));
            });
        }
    }, []);

    useEffect(()=>console.log('AddTicketForm::formProps',formProps),[formProps]);

    const onSubmit = async (e) => {
        const { date_due } = formProps;
        e.preventDefault();

        // Validate form
        setErrorMsg('');
        const requiredFields = ['type_id', 'title', 'description', 'priority_id', 'company_id', 'estimate_required'];

        // Set due date if not provided but suggested
        if ((!date_due || date_due === 'null') && suggestedDate) {
            formProps.date_due = convertDateToTimestamp(suggestedDate);
        }

        // Check if any required field is missing
        for (let key in formProps) {
            if (formProps.hasOwnProperty(key) && requiredFields.includes(key) && (!formProps[key] || formProps[key] === '')) {
                setErrorMsg(`The ${key} is invalid.`);
                return;
            }
        }

        try {
            // Add the new ticket
            const newTicket = await addTicket(formProps);
            console.log('AddTicketForm::newTicket', formProps, newTicket);

            // Clear form using a React-friendly approach if possible
            const formElement = document.getElementById('add-ticket-form');
            if (formElement) formElement.reset();

            // Add activity for the newly created ticket
            await addActivity({
                ticket_id: newTicket.ticket_id,
                contact_id: userContext.contact.contact_id,
                note: 'New Ticket Created.'
            });

            // Fetch the default agent and assign the ticket
            console.log('AddTicketForm::onSubmit::newTicket',newTicket);
            const agent = await fetchDefaultAgent(newTicket.company_id);
            if(agent) {
                console.log('AddTicketForm::onSubmit::agent', agent);
                await assignTicket(newTicket, agent);
                console.log(`Ticket ${newTicket.ticket_id} assigned to agent ${agent.contact_id}`);
            }

            // Trigger any additional submit handler
            if (submitHandler) submitHandler(formProps);

        } catch (error) {
            console.error('Error during ticket creation or assignment process:', error);
            setErrorMsg('An error occurred while processing your request. Please try again.');
        }
    };


    const handlePriorityChange = (e) => {
        const { value } = e.target;
        const { date_due } = formProps;
        setFormProps({...formProps, priority_id: value});

        if (date_due === 'null' || !date_due) {
            console.log('handlePriorityChange::updatingDueDate', value);
            const suggestedDate = calculateBusinessDays(new Date(), parseInt(value));
            suggestedDate.setHours(16,0,0,0);
            setSuggestedDate(suggestedDate);
        }
    };

    const handleDateChange = (value) => {
        console.log('handleDateChange', value);
        if (value && value instanceof Date) {
            value.setHours(16, 0, 0, 0);
            const timestamp = convertDateToTimestamp(value);
            setFormProps({...formProps, date_due: timestamp});
        } else {
            setFormProps({...formProps, date_due: 'null'});
        }
    };

    return (
        <form id='add-ticket-form' className="ux-form" onSubmit={onSubmit}>
            {errorMsg !== '' ? renderAlert(errorMsg, 'danger') : null}
            <div className='mb-3'>
                <label htmlFor='type' className='w-100'><strong>Type</strong>
                    <div className='d-flex flex-wrap pt-2'>
                        {TICKET_TYPES.map(type => (
                            <span className='form-check' key={type.id}>
                                <label htmlFor={`type-${type.id}`} className='text-nowrap'>
                                    <input type="radio" id={`type-${type.id}`}
                                           name="type_id" value={type.id}
                                           onChange={(e) => setFormProps({
                                               ...formProps,
                                               type_id: e.target.value.toString()
                                           })}
                                    /> {type.name}</label>
                            </span>
                        ))}
                    </div>
                </label>
            </div>
            <div className="mb-3" style={{width: '100% !important'}}>
                <label htmlFor="shortDesc" className='w-100 '><strong>Title</strong>
                    <input type="text" id="shortDesc" name="shortDesc" className="form-control"
                           onChange={(e) => setFormProps({...formProps, title: e.target.value})}/>
                </label>
            </div>
            <div className="mb-3">
                <label htmlFor="longDesc" className='w-100'><strong>Description</strong> (Uses markdown)
                    <MarkdownInput value={formProps.description !== 'null' ? formProps.description : ''}
                                   changeHandler={value => setFormProps({...formProps, description: value})}/>
                </label>
            </div>
            <div className='mb-3'>
                <label htmlFor='priority' className='w-100'><strong>Priority</strong>
                    <div className='d-flex flex-wrap pt-2'>
                        {TICKET_PRIORITIES.map(priority => (
                            <div className='form-check' key={priority.id}>
                                <label htmlFor={`priority-${priority.id}`}>
                                    <input type="radio" id={`priority-${priority.id}`} name="priority_id"
                                           value={priority.id}
                                           onChange={handlePriorityChange}
                                    /> {priority.name}</label>
                            </div>
                        ))}
                    </div>
                </label>
            </div>
            <div className="d-sm-flex justify-content-between">
                <label className='mb-3 w-xs-100'><strong>Due Date</strong> (optional)
                    <DatePicker id='ticket-due-date' className='datepicker'
                                defaultValue={formProps.date_due === 'null' ? null : new Date(formProps.date_due)}
                                dropUp={true}
                                placeholder={suggestedDate ? formatDate(suggestedDate) : 'mm/dd/yyyy'}
                                onChange={handleDateChange}
                    />
                </label>
                <label htmlFor='estimate-required' className=' w-50'><strong>Estimate Required</strong>
                    <div className='d-flex flex-wrap pt-2'>
                        <div className='form-check'>
                            <label htmlFor={`required-yes`} className='text-nowrap me-3'>
                                <input type="radio" id={`required-yes`}
                                       name="estimate_required"
                                       onChange={() => setFormProps({
                                           ...formProps,
                                           estimate_required: "1"
                                       })}
                                /> Yes</label>
                        </div>
                        <div className='form-check'>
                            <label htmlFor={`required-no`} className='text-nowrap me-3'>
                                <input type="radio" id={`required-no`} checked
                                       name="estimate_required"
                                       onChange={() => setFormProps({
                                           ...formProps,
                                           estimate_required: "0"
                                       })}
                                /> No</label>
                        </div>
                    </div>
                </label>
            </div>
            <button
                className="w-100 btn btn-lg btn-primary"
                type="submit"
                style={{marginTop: '5px'}}
            >Add New Ticket
            </button>
        </form>
    );


}

export default AddTicketForm;