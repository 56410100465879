import React, {useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {UserContext, UserProvider} from "../_contexts/UserContext";
import Login from "./Login";
import Header from "./_ui/Header";
import Footer from "./_ui/Footer";
import Ticket from "./Ticket";
import ClientDashboard from "./ClientDashboard";
import AgentDashboard from "./AgentDashboard";
import AdminDashboard from "./AdminDashboard";
import useLocalStorage from "../_hooks/useLocalStorage";
import Invoice from "./Invoice";
import MarkdownInput from "./_ui/formElements/MarkdownInput";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import PrivateRoute from "./Helpers/PrivateRoute";
import '../styles.scss';
import Agreement from "./Agreement";
import AdminInvoices from "./AdminInvoices";
import ClientEditor from "./ClientEditor";
import RegistrationForm from "./_ui/formElements/RegistrationForm";
import AdminStats from "./AdminStats";
import {DashboardProvider} from "../_contexts/DashboardContext";


function App() {

    return (
        <React.StrictMode>
            <UserProvider>
                <div id='app-wrapper'>
                    <Router>
                        <Header/>
                        <div id='component-wrapper' className={`bg-light border-bottom-1`}>
                            <Routes>
                                <Route path="/" element={<Login/>}/>
                                <Route path="login" element={<Login/>}/>
                                <Route path="register" element={<RegistrationForm />}/>
                                <Route path="verifyemail/:verificationCode" element={<VerifyEmail/>}/>
                                <Route path="forgotpassword" element={<ForgotPassword/>}/>
                                <Route path="resetpassword/:resetCode" element={<ResetPassword/>}/>
                                <Route path="admins" element={<PrivateRoute><DashboardProvider><AdminDashboard/></DashboardProvider></PrivateRoute>}/>
                                <Route path="admins/invoices" element={<PrivateRoute><AdminInvoices/></PrivateRoute>}/>
                                <Route path="admins/invoices/:offset" element={<PrivateRoute><AdminInvoices/></PrivateRoute>}/>
                                <Route path="admins/stats" element={<PrivateRoute><AdminStats/></PrivateRoute>}/>
                                <Route path="agents" element={<PrivateRoute><DashboardProvider><AgentDashboard/></DashboardProvider></PrivateRoute>}/>
                                <Route path="agents/:agentId" element={<PrivateRoute><DashboardProvider><AgentDashboard/></DashboardProvider></PrivateRoute>}/>
                                <Route path="clients" element={<PrivateRoute><DashboardProvider><ClientDashboard/></DashboardProvider></PrivateRoute>}/>
                                <Route path="clients/:clientId" element={<PrivateRoute><DashboardProvider><ClientDashboard/></DashboardProvider></PrivateRoute>}/>
                                <Route path="clients/:clientId/edit" element={<PrivateRoute><ClientEditor/></PrivateRoute>}/>
                                <Route path="clients/:clientId/invoices/:invoiceId" element={<PrivateRoute><Invoice/></PrivateRoute>}/>
                                <Route path="clients/:clientId/tickets/:ticketId" element={<PrivateRoute><Ticket/></PrivateRoute>}/>
                                <Route path="agreement" element={<Agreement />} />

                            </Routes>
                        </div>
                    </Router>
                    <Footer/>
                </div>
            </UserProvider>
        </React.StrictMode>
    );
}

export default App;
