/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import React from 'react';

const ListBlock = ({title, items, classes=null,style=null}) => {
    return (
        <div className={`ticket-list h-100 bg-light rounded p-3 shadow-sm flex-grow-1 d-flex flex-column ${classes}`} style={style}>
            <h3>{title}</h3>
            <div style={{flexGrow:'1',overflowY:'auto'}}>
            {items}
            </div>
        </div>
    );
}

export default ListBlock;