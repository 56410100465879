/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 7/18/2023
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {UserContext} from "../_contexts/UserContext";
import useCompanyInfo from "../_hooks/useCompanyInfo";
import {
    addCompanyContact,
    deleteCompanyContact,
    fetchCompany,
    fetchCompanyContacts, fetchDefaultAgent,
    updateCompany, updateCompanyContact, updateCompanyMeta
} from "../_utilities/companyUtilities";
import {getChangedObjects, getNewObjects, getRemovedObjects, search} from "../_utilities/reactUtils";
import {addContact, sendResetCode, sendWelcome, updateContact} from "../_utilities/contactUtilities";
import CompanyForm from "./_ui/formElements/CompanyForm";
import ContactsForm from "./_ui/formElements/ContactsForm";
import SelectorInput from "./_ui/formElements/SelectorInput";
import supportAPI from "../_apis/supportAPI";

const ClientEditor = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {userContext} = useContext(UserContext);
    const [allowed, setAllowed] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const company = useCompanyInfo();
    const [assigned, setAssigned] = useState({metaId: null, meta_value: null});
    const [companyInfo, setCompanyInfo] = useState({name: '', url: '', description: ''});
    const [contacts, setContacts] = useState([]);
    const [agentList, setAgentList] = useState(null);

    useEffect(() => {
        fetchCompany(params.clientId).then(response => {
            company.setInfo(response);
            setCompanyInfo(() => response);
        });
        fetchCompanyContacts(params.clientId).then(response => {
            console.log('ClientEditor::company.contacts', response);
            company.setContacts(response);
            setContacts(() => response)
        });
        fetchDefaultAgent(params.clientId).then(agent => {
            console.log('ClientEditor::company.meta.default_agent', agent);
            setAssigned({metaId: agent.meta_id, meta_value: agent.contact_id});
        });

    }, [params]);

    useEffect(() => {
        // is admin
        if (userContext.contact.group_id === "1") {
            setAllowed(() => true);
        }
        // is associated with company
        if (userContext.contact.company) {
            userContext.contact.company.map(co => {
                if (parseInt(params.clientId) === parseInt(co.company_id)) {
                    setAllowed(() => true);
                }
            });
        }
        search('contact', [`group_id IN (1,2,3,4)`]).then(response => {
            setAgentList(response)
        });
    }, []);


    const handleCompanyInfoChange = (e) => {
        const {name, value} = e.target;
        setCompanyInfo((prevInfo) => ({...prevInfo, [name]: value}));
    };

    const handleAddContact = (newContact) => {
        setContacts([...contacts, newContact]);
    };

    const handleEditContact = (contact) => {
        const updatedContacts = contacts.map(c => {
            if (c.email === contact.email) {
                return contact
            }
            return c;
        });
        setContacts(updatedContacts);
    }

    const handleRemoveContact = (email) => {
        setContacts(contacts.filter(contact => contact.email !== email));
    };

    const save = async () => {
        try {
            setSaveDisabled(() => true);

            // Update company info
            const updatedCompany = await updateCompany(companyInfo);
            const updatedContacts = await saveContacts();
            const assignedAgent = await updateCompanyMeta({
                id:         assigned.metaId,
                company_id: companyInfo.company_id,
                meta_key: 'default_agent',
                meta_value: assigned.meta_value
            });
            updateCompany(companyInfo).then(() => {
                saveContacts().then(() => {
                    navigate(`/clients/${params.clientId}`)
                }).catch(() => console.error("ClientEditor::ERROR: Contacts not saved."));
            });


            setSaveDisabled(() => false);

        } catch (err) {
            console.error('ClientEditor::ERROR: ' + err);
            setSaveDisabled(() => false);
        }
    };

    const saveContacts = async () => {
        console.log('ClientEditor::saveContacts', contacts);
        for (const originalContact of company.contacts) {
            if (!contacts.some(updatedContact => updatedContact.contact_id === originalContact.contact_id)) {
                console.log('DELETING', originalContact);
                await deleteCompanyContact(company.info.company_id, originalContact.contact_id); // Wait for
                                                                                                 // removeContact
                setContacts((prev) => [...prev.filter(c => c.email !== originalContact.email)]);
            }
        }

        for (const updatedContact of contacts) {
            const existingContact = company.contacts.find(c => c.email === updatedContact.email);

            console.log('PROCESSING', updatedContact, existingContact);
            if (!existingContact) {
                console.log("NEW COMPANY CONTACT");
                const existing = await search('contact', [`email="${updatedContact.email}"`], 'AND', null, true);
                if (existing) {
                    console.log("EXISTING CONTACT");
                    const newCompanyContact = {
                        company_id: company.info.company_id,
                        contact_id: existing.contact_id,
                        title:      updatedContact.title
                    };
                    console.log('ADDING NEW COMPANY CONTACT', newCompanyContact);
                    const newContact = await addCompanyContact(newCompanyContact); // Wait for addContact
                } else {
                    console.log("NEW CONTACT");
                    try {
                        const contactData = {
                            first_name: updatedContact.first_name,
                            last_name:  updatedContact.last_name,
                            email:      updatedContact.email,
                            phone:      updatedContact.phone,
                            group_id:   5,
                            password:   Date.now().toString(36) + Math.random().toString(36).substring(2)
                        };

                        console.log('ADDING NEW CONTACT', contactData);
                        const newContact = await addContact(contactData);
                        const newCompanyContact = {
                            company_id: company.info.company_id,
                            contact_id: newContact.contact_id,
                            title:      updatedContact.title
                        }
                        console.log('ADDING NEW COMPANY CONTACT', newCompanyContact);
                        await addCompanyContact(newCompanyContact);

                        sendWelcome(newContact.contact_id).then(response => {
                            console.log(`Sent welcome to contact #${newContact.contact_id}.`);
                        });
                    } catch (err) {
                        console.error('ClientEditor::addContact::FAIL', err);
                        return false;
                    }
                }
            } else {
                console.log("ALREADY A COMPANY CONTACT", updatedContact);
                try {
                    // check if different
                    if (JSON.stringify(existingContact) !== JSON.stringify(updatedContact)) {
                        console.log('UPDATING CONTACT', {
                            contact_id: existingContact.contact_id,
                            first_name: updatedContact.first_name,
                            last_name:  updatedContact.last_name,
                            email:      updatedContact.email,
                            phone:      updatedContact.phone
                        });
                        await updateContact(existingContact.contact_id, {
                            contact_id: existingContact.contact_id,
                            first_name: updatedContact.first_name,
                            last_name:  updatedContact.last_name,
                            email:      updatedContact.email,
                            phone:      updatedContact.phone
                        });
                        console.log('UPDATING COMPANY CONTACT', {
                            company_id: company.info.company_id,
                            contact_id: existingContact.contact_id,
                            title:      updatedContact.title
                        })
                        await updateCompanyContact({
                            company_id: company.info.company_id,
                            contact_id: existingContact.contact_id,
                            title:      updatedContact.title
                        });
                    }
                } catch (err) {
                    console.error('ClientEditor::updateContact::FAIL', err);
                }
            }
        }
        return true;
    }

    return (<>
        {allowed ? <>
            {company ? <div className={`py-2 px-3 m-3 mx-auto`} style={{width: '90%', maxWidth: '1180px'}}>
                <p className='text-center pt-3 pb-1 mb-0'>You are now editing</p>
                <h2 className={`text-center`}>{company.info.name}</h2>
                <div className={`pb-3`}>
                    {allowed && agentList ? <div className="mb-3" style={{width: '100% !important'}}>
                        <label htmlFor={`assignee_id`}><strong>Assign Default Agent</strong></label>
                        <SelectorInput inputClass={`mb-3`}
                                       inputInstructions={`Assign to...`}
                                       inputName={`assignee_id`}
                                       inputValue={assigned.meta_value}
                                       inputOptions={agentList && agentList.map ? agentList.map(item => {
                                           return {label: `${item.first_name} ${item.last_name}`, value: item.contact_id}
                                       }) : null}
                                       onChange={(value) => {
                                           setAssigned(prev => ({...prev, meta_value: value}));
                                       }}
                        />
                    </div> : null}
                    <CompanyForm companyInfo={companyInfo} onChange={handleCompanyInfoChange}/>
                </div>
                <div>
                    <ContactsForm
                        contacts={contacts}
                        onAddContact={handleAddContact}
                        onEditContact={handleEditContact}
                        onRemoveContact={handleRemoveContact}
                    />
                </div>
                <div className={`d-flex justify-content-center`}>
                    <button disabled={saveDisabled} className={`btn btn-primary btn-lg mx-2 `} onClick={save}>Update
                        Company
                    </button>
                    <button onClick={() => navigate(`/clients/${company.info.company_id}`)}
                            className={`btn btn-danger btn-lg mx-2`}>Cancel
                    </button>
                </div>
            </div> : <p className={`text-center`}>Oops! Loading your company info now.</p>}
        </> : <div className='alert alert-danger m-3'>You are not authorized to view this page.</div>}
    </>);
}

export default ClientEditor;