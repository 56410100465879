/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import StatBlock from "./_ui/StatBlock";
import {formatCurrency} from "../_utilities/formattingUtils";
import AddTicketForm from "./_ui/formElements/AddTicketForm";
import TicketItem from "./tickets/TicketItem";
import {getUID} from "bootstrap/js/src/util";
import useInterval from "../_hooks/useInterval";
import {useUser} from "../_contexts/UserContext";
import {CLIENT_MODES, STEP_VALUE, TICKET_PRIORITIES, TICKET_STATUS} from "../system/defaults";
import supportAPI from "../_apis/supportAPI";
import {useNavigate, useParams} from "react-router";
import TicketItemCompleted from "./tickets/TicketItemCompleted";
import Attachments from "./_ui/Attachments";
import MarkdownInput from "./_ui/formElements/MarkdownInput";
import ReactMarkdown from "react-markdown";
import SelectorInput from "./_ui/formElements/SelectorInput";
import CompanySelector from "./_ui/CompanySelector";
import ListBlock from "./_ui/ListBlock";

const ClientDashboard = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [allowed, setAllowed] = useState(true);
    const {userContext} = useUser();

    const [showAdd, setShowAdd] = useState(false);
    const [editNotes, setEditNotes] = useState(false);
    const [refreshing, setRefreshing] = useState(true);

    const creditTotal = useRef(0);
    const monthTotal = useRef(0);
    const ytdTotal = useRef(0);

    const [ticketList, setTicketList] = useState([]);
    const [overdue, setOverdue] = useState([]);
    const [backlog, setBacklog] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [inProgress, setInProgress] = useState([]);
    const [testing, setTesting] = useState([]);
    const [completedList, setCompletedList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companyNotes, setCompanyNotes] = useState('');

    useEffect(() => {
        refresh();
    }, []);

    useInterval(() => {
        refresh();
    }, refreshing ? 1000 : null);

    return (
        <>
            {allowed ? <>
                    <div className={`d-flex border-bottom flex-wrap`}>
                        <StatBlock title={`Active`} number={ticketList.filter(item => parseInt(item.status_id) < 5).length}
                                   classes={`border-end border-bottom bg-secondary text-white`}
                                   style={{minWidth: "150px"}}
                        />
                        {/*<StatBlock title={`Credit Total`} number={formatCurrency(creditTotal.current)}*/}
                        {/*           classes={`border-bottom border-end bg-secondary text-white`}/>*/}
                        <StatBlock title={`Current Total`} number={formatCurrency(monthTotal.current)}
                                   classes={`border-end border-bottom bg-secondary text-white`}
                                   style={{minWidth: "150px"}}
                        />
                        <StatBlock title={`YTD Total`} number={formatCurrency(ytdTotal.current)}
                                   classes={`border-end border-bottom bg-secondary text-white`}
                                   style={{minWidth: "150px"}}
                        />
                    </div>

                    {companyList && companyList.length > 1 ?
                        <CompanySelector companyList={companyList} selectedCompany={selectedCompany} changeHandler={(e) => {
                            if(e.target.value > 0){
                                setTicketList([]);
                                setCompletedList([]);
                                navigate(`/clients/${e.target.value}`);
                                setSelectedCompany(() => companyList.find(co => co.company_id === e.target.value));
                            }
                        }} />
                        : null}
                    {selectedCompany && parseInt(userContext.contact.group_id) <= 1 ? <div id={`client-title`} className={`d-flex justify-content-between align-items-center py-3 px-3 mx-3 text-nowrap`}>
                            <h2 className={`flex-grow-1 ms-3`}>{selectedCompany.name} <span className={`h6`}><a href={`/clients/${selectedCompany.company_id}/edit`}>Edit</a></span></h2>
                            <div className={`w-25 me-3`}>
                            <SelectorInput inputClass={`mb-0`}
                                           inputName={`company_mode`}
                                           inputValue={selectedCompany.mode ? selectedCompany.mode : ''}
                                           inputOptions={CLIENT_MODES.map(item => {
                                               return {label: item.name, value: item.id}
                                           })}
                                           onOpen={(opened) => setRefreshing(() => !opened)}
                                           onChange={(value) => changeCompanyMode(value)}
                            />
                            </div>
                        </div>: null}
                    {selectedCompany && parseInt(userContext.contact.group_id) >= 2 ? <h2 className={`text-center py-3 px-3 mx-3`}>{selectedCompany.name} <span className={`h6`}><a href={`/clients/${selectedCompany.company_id}/edit`}>Edit</a></span></h2> : null}
                    <div className={`row px-3 mx-3`}>
                        <div className={`col-xs-12 col-md-6 col-lg-3 mb-3`}>
                            <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                                <h3>{TICKET_STATUS[0].name}</h3>
                                <button type="button" className="btn btn-primary mb-3 border-0" data-toggle="modal"
                                        onClick={() => {
                                            setRefreshing(() => false);
                                            setShowAdd(() => true);
                                        }}
                                        data-target=".bd-example-modal-lg">
                                    <i className={`bi bi-plus-circle`}/> ADD NEW TICKET
                                </button>
                                <div style={{flexGrow: '1', overflowY: 'auto'}}>
                                    {ticketList.filter(item => item.status_id === "1" || (!item.assignee_id && item.status_id === "7") ).map(item =>
                                        <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                                </div>
                            </div>
                        </div>
                        <div className={`col-xs-12 col-md-6 col-lg-3 mb-3`}>
                            <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                                <h3>{TICKET_STATUS[1].name}</h3>
                                {ticketList.filter(item => item.status_id === "2" || (parseInt(item.status_id) >= 7)).map(item =>
                                    <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                            </div>
                        </div>
                        <div className={`col-xs-12 col-md-6 col-lg-3 mb-3`}>
                            <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                                <h3>{TICKET_STATUS[2].name}</h3>
                                {ticketList.filter(item => item.status_id === TICKET_STATUS[2].id.toString()).map(item =>
                                    <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                            </div>
                        </div>
                        <div className={`col-xs-12 col-md-6 col-lg-3`}>
                            <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                                <h3>{TICKET_STATUS[3].name}</h3>
                                {ticketList.filter(item => item.status_id === TICKET_STATUS[3].id.toString()).map(item =>
                                    <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                            </div>
                        </div>
                    </div>
                    {selectedCompany && parseInt(userContext.contact.group_id) <= 4 ? <>
                        <div className={`d-flex px-3 mx-3`}>
                            <div className={`flex-grow-1 bg-white rounded p-3 m-3 shadow-sm`}>
                                <h4 className={`pe-3 me-3`}><i className={`bi bi-journal me-1`} />Notes...</h4>
                                {editNotes === true ? <>
                                    <MarkdownInput value={companyNotes}
                                                   changeHandler={(value) => setCompanyNotes(() => value)}
                                                   height='300px'/>
                                    <div className={`d-flex justify-content-end`}>
                                        <button className={`btn btn-success btn-sm mt-3 me-3`}
                                                onClick={() => {
                                                    const companyData = {
                                                        company_id:selectedCompany.company_id,
                                                        note: companyNotes
                                                    }
                                                    supportAPI.put(`/company/${selectedCompany.company_id}`,JSON.stringify(companyData)).then(response => {
                                                        console.log('company notes updated',response.data);
                                                        setRefreshing(() => true);
                                                        setEditNotes(() => !editNotes)
                                                    });
                                                }}>Update Description
                                        </button>
                                        <button className={`btn btn-danger btn-sm mt-3`}
                                                onClick={() => {
                                                    setRefreshing(() => true);
                                                    setEditNotes(() => !editNotes)
                                                }}>Cancel
                                        </button>
                                    </div>
                                </> : <>
                                    <ReactMarkdown>{`${selectedCompany.note ? selectedCompany.note : "Click the Edit Notes button to add a note."}`}</ReactMarkdown>
                                    <div className={`d-flex justify-content-end`}>
                                        <button className={`btn btn-success btn-sm`}
                                                onClick={() => {
                                                    setRefreshing(() => false);
                                                    setEditNotes(() => !editNotes)
                                                }}>Edit Notes
                                        </button>
                                    </div>
                                </>}
                            </div>
                            <Attachments targetType='company' target={selectedCompany} classes={`w-33 bg-white rounded p-3 m-3 shadow-sm`}/>
                        </div>
                    </> : null}
                    <div className={`row m-3 p-3`}>
                        <div className={`col p-3 m-1`}>
                            <h3>{TICKET_STATUS[4].name}</h3>
                            {ticketList.filter(item => item.status_id === TICKET_STATUS[4].id.toString())
                                .sort(function (a, b) {
                                    // Turn your strings into dates, and then subtract them
                                    // to get a value that is either negative, positive, or zero.
                                    return new Date(b.date_completed) - new Date(a.date_completed);
                                })
                                .map(item =>
                                    <TicketItemCompleted key={getUID('ticket-')} ticket={item}/>)
                            }
                        </div>
                    </div>
                    {showAdd ? (
                        <div className="ux-modal">
                            <div className="ux-modal-content bg-white rounded shadow-lg p-3 position-relative">
                                <h2>Add Ticket</h2>
                                <i className={`bi bi-x-circle-fill`}
                                   style={{position: 'absolute', top: '5px', right: '10px', fontSize: '30px'}}
                                   onClick={() => {
                                       setShowAdd(() => false);
                                       setRefreshing(() => true);
                                   }}
                                ></i>
                                <AddTicketForm companyId={selectedCompany.company_id} submitHandler={() => {
                                    setShowAdd(() => false);
                                    setRefreshing(() => true);
                                }}/>
                            </div>
                        </div>
                    ) : null}

                </>
                : <div className='alert alert-danger m-3'>You are not authorized to view this page.</div>}
        </>
    );

    function changeCompanyHandler(e) {
        console.log('ClientDashboard::changeCompanyHandler', e.target.value);
        setSelectedCompany(() => companyList.find(co => co.company_id === e.target.value));
    }

    function changeCompanyMode(modeId){
        console.log('ClientDashboard::changeCompanyMode', modeId);
        supportAPI.put(`/company/${selectedCompany.company_id}`, JSON.stringify({mode: modeId})).then(response => {
            ytdTotal.current = response.data.total_time ? parseInt(response.data.total_time.toString()) * STEP_VALUE : 0;
        });
    }


    function refresh() {
        if (!refreshing) return;

        if(ticketList){
            // console.log('REFRESH::ticketList',backlog,assigned,inProgress,testing);
            setBacklog(prev => ticketList.filter(t => t.status_id === '1'));
            setBacklog(prev => ticketList.filter(t => t.status_id === '1'));
            setAssigned(prev =>ticketList.filter(t => t.status_id === '2'));
            setInProgress(prev =>ticketList.filter(t => t.status_id === '3'));
            setTesting(prev =>ticketList.filter(t => t.status_id === '4'));
        }

        if ((params.clientId && userContext.contact.group_id === "1") || (userContext.contact.company && userContext.contact.company[0].company_id)) {
            // allowed to view page
            setAllowed(() => true);

            // load company list
            if(companyList && companyList.length < 1){
                if(userContext.contact.group_id === "1"){
                    // show full company list
                    supportAPI.get(`/company`).then(response => {
                        setCompanyList(() =>
                            response.data.sort((a, b) => {
                                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                                    return 1;
                                } else if (a.name.toUpperCase() < b.name.toUpperCase()) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }));
                    });
                } else {
                    // show client company list
                    setCompanyList(()=>userContext.contact.company);
                }

                // set selectedCompany
                if (!selectedCompany) {
                    if (!selectedCompany) setSelectedCompany(() => userContext.contact.company[0]);

                    if (params.clientId) {
                        supportAPI.get(`/company/${params.clientId}`).then(response => {
                            if (!response.data) {
                                setAllowed(() => false);
                                return;
                            }
                            setSelectedCompany(() => response.data);
                        });

                        if (!userContext.contact.company.find(co => co.company_id = params.clientId) && userContext.contact.group_id !== "1") {
                            setAllowed(() => false);
                            return;
                        } else {

                        }
                    }
                } else {
                    console.log('ClientDashboard::refresh::selectedCompany',selectedCompany.company_id);

                }
            }

            // load selected company tickets
            if(selectedCompany) {
                setCompanyNotes(selectedCompany.note);
                supportAPI.get(`/company/${selectedCompany.company_id}/tickets`).then(response => {
                    if (response.data && response.data.length > 0 && response.data.filter) {
                        setTicketList(() => response.data);
                    } else {
                        setTicketList(() => []);
                    }
                });
                supportAPI.get(`/company/${selectedCompany.company_id}/currenttime`).then(response => {
                    monthTotal.current = response.data.total_time ? parseInt(response.data.total_time.toString()) * STEP_VALUE : 0;
                });
                supportAPI.get(`/company/${selectedCompany.company_id}/ytdtime`).then(response => {
                    ytdTotal.current = response.data.total_time ? parseInt(response.data.total_time.toString()) * STEP_VALUE : 0;
                });
            }

        } else {
            setAllowed(() => false);
        }
    }
};

export default ClientDashboard;