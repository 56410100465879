/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/9/2022
 * for support.userx.co
 */

import React, { useState, useEffect } from 'react';
import { getUID } from "bootstrap/js/src/util";

const SelectorInput = ({ inputInstructions, inputName, inputValue, inputOptions, inputClass, onOpen, onChange }) => {
    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setName(inputName || getUID('selector_'));
        setValue(inputValue);
        setOptions(inputOptions || []);
    }, [inputName, inputValue, inputOptions]);

    const handleBlur = (e) => {
        console.log('SelectorInput::handleBlur', e.target.dataset.name);
        if (onChange) {
            onChange(e.target.value);
        }
    };

    const handleChange = (e) => {
        console.log('SelectorInput::handleChange', e.target.dataset.name);
        if (value !== e.target.value && onChange) {
            setValue(e.target.value);
            onChange(e.target.value);
        }
    };

    return (
        <select
            id={name}
            className={`form-select ${inputClass}`}
            name={name}
            value={value}
            onChange={handleChange}
            // onBlur={handleBlur}
        >
            {inputInstructions && <option key='instructions' value='null'>{inputInstructions}</option>}
            {options.map((option) => (
                <option key={option.label} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default SelectorInput;
