/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../_contexts/UserContext";
import {useNavigate} from "react-router";
import supportAPI from "../_apis/supportAPI";
import {Link} from "react-router-dom";

const VerifyEmail = () => {
    const navigate = useNavigate();

    const submitHandler = () => {

    }

    return (
        <div className={`d-flex justify-content-center`}>
            <div className={`p-3 m-2 text-center`}>
                <h2>Verify Email</h2>
            </div>
        </div>
    );
};

export default VerifyEmail;