/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import React from 'react';

const StatBlock = ({title,number = 0,type = '', classes=null,style=null})=>{
    return (
        <div className={`${!classes || classes.indexOf('bg-') === -1 ? "bg-light" : null} p-3 text-center col ${classes}`} style={style}>
            <strong>{title}</strong>
            <p className='m-0' style={{fontSize:'36px'}}>{type === '$' ? type : ''}{number}{type !== '$' ? type : ''}</p>
        </div>
    );
}

export default StatBlock;