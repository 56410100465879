/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 2/22/2023
 * for userx-support
 */

import supportAPI from "../_apis/supportAPI";

export const addNotification = async (notification) => {
    console.log('addNotification', notification);
    const noteResponse = await supportAPI.post(`/notifications`, JSON.stringify(notification));
    if (!noteResponse.data) {
        throw new Error('Notification not created.');
    }
    return noteResponse.data;
}

export const deleteNotification = async(notification_id) => {
    const noteResponse = await supportAPI.delete(`/notifications/${notification_id}`);
    if (!noteResponse.data) {
        throw new Error('Notification not deleted.')
    }
    return noteResponse.data;
}

export const deleteAllByContact = async(contact_id) => {
    const noteResponse = await supportAPI.delete(`/contact/${contact_id}/notifications`);
    if (!noteResponse.data) {
        throw new Error('Notifications not deleted.')
    }
    return noteResponse.data;
}
