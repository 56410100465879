/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/3/2023
 * for userx-support
 */

import React, {useContext} from "react";
import {UserContext} from "../../_contexts/UserContext";
import {useNavigate} from "react-router";

const PrivateRoute = ({children}) => {
    const {userContext} = useContext(UserContext);
    if (!userContext.isLoggedIn) {
        window.location.href = "https://support.userx.co/login";
    }
    return children;
}

export default PrivateRoute;