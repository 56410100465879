/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 11/17/2023
 * for userx-support
 */

import React from 'react';

const CompanySelector = ({companyList, selectedCompany, changeHandler})=>{
    return (
        companyList && companyList.length > 1 ?
            <div id='company-selector' className='d-flex bg-secondary px-3 py-2 text-white align-items-center flex-grow-1'>
                <label htmlFor='company-select' className='text-nowrap me-2'><strong>Go To
                    Company:</strong></label>
                <select className='form-select' name='company_id'
                        value={selectedCompany ? selectedCompany.company_id : companyList[0].company_id}
                        onChange={changeHandler}>
                    <option value={-1}>Select company...</option>
                    {companyList.length > 1 ? companyList.map(co => <option key={co.company_id}
                                                                            value={co.company_id}>{co.name}</option>) : null}
                </select>
            </div>
            : null

    );
};

export default CompanySelector;