/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 2/23/2023
 * for userx-support
 */

import React, {useState} from 'react';

const Checkbox = ({name, label, checked, changeHandler, classes, strikethrough = true}) => {
    const [active, setActive] = useState(checked ? checked : false);
    const onChangeHandler = () => {
        setActive(()=>!active);
        if(changeHandler){
            changeHandler(!active);
        }
    }

    return (
        <div className={`form-check ps-1 d-flex align-items-center ${classes}`}
            style={{marginBottom: "0px !important"}}>
            <label className={`d-flex justify-content-start align-items-start`} style={{lineHeight:'1em'}}>
                <input type='checkbox' name={name} onChange={onChangeHandler} style={{marginTop:'2px'}}
                   checked={active} className={'me-2'}/>
                {active && strikethrough ? <del>{label}</del> : <span>{label}</span> }
            </label>
        </div>
    );
};

export default Checkbox;