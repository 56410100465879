/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 */

import supportAPI from "../_apis/supportAPI";
import {search} from "./reactUtils";
import {fetchCompany} from "./companyUtilities";


export const addContact = async (contact) => {
    const contactResponse = await supportAPI.post(`/contact`, JSON.stringify(contact));
    if (!contactResponse.data) {
        throw new Error('Contact not created.');
    }
    return contactResponse.data;
}

export const updateContact = async (contactId, contact) => {
    const contactResponse = await supportAPI.put(`/contact/${contactId}`, JSON.stringify(contact));
    if(!contactResponse.data){
        throw new Error('Contact not updated.');
    }
    return contactResponse.data;
}

export const fetchContact = async (contactId) => {
    const contactResponse = await supportAPI.get(`/contact/${contactId}`);
    if (!contactResponse.data) {
        throw new Error('Contact not found.')
    }
    return contactResponse.data;
}

export const deleteContact = async(contactId) => {
    const contactResponse = await supportAPI.delete(`/contact/${contactId}`);
    if (!contactResponse.data) {
        throw new Error('Contact not found.')
    }
    return contactResponse.data;
}

export const sendNote = async (contactId, subject, message) => {
    const contactResponse = await supportAPI.post(`/contact/${contactId}/sendmail`, JSON.stringify({subject:subject,message:message}));
    if(!contactResponse.data){
        throw new Error('Note not sent.');
    }
    console.log('contactUtilities::sendNote', contactResponse.data);
    return contactResponse.data;
}

export const sendResetCode = async (contactId) => {
    const contactResponse = await supportAPI.post(`/contact/${contactId}/sendresetcode`);
    if (!contactResponse.data) {
        throw new Error('Reset code not sent.')
    }
    console.log('contactUtilities::sendResetCode',contactResponse.data);
    return contactResponse.data;
}

export const sendWelcome = async (contactId) => {
    const contactResponse = await supportAPI.post(`/contact/${contactId}/sendwelcome`);
    if (!contactResponse.data) {
        throw new Error('Verification email not sent.')
    }
    console.log('contactUtilities::sendWelcome',contactResponse.data);
    return contactResponse.data;
}

export const sendActivity = async (contactId,note) => {
    const contactResponse = await supportAPI.post(`/contact/${contactId}/sendactivity`,JSON.stringify({activity:note}));
    if (!contactResponse.data) {
        throw new Error('Activity email not sent.')
    }
    return contactResponse.data;
}

export const sendNewTicket = async (ticket) => {
    const company = await ticket.company_id ? await fetchCompany(ticket.company_id) : null;
    sendActivity(1, `<p>A new ticket has been added${company ? ` to ${company.name}` : null}: <strong><a href="https://support.userx.co/clients/${ticket.company_id}/tickets/${ticket.ticket_id}">ticket #${ticket.ticket_id}</a> - ${ticket.title}:</strong></p>
<p><strong>DO NOT REPLY</strong> to this email. Please visit <a href="https://support.userx.co/clients/${ticket.company_id}/tickets/${ticket.ticket_id}">User X Support</a> to respond. Thanks.</p>
<p>Have a great day from the User X Support Team.</p>`).then(response => {
        if(response.data){
            return true
        }
    });
    return false;
}
export const verifyResetCode = async (resetCode) => {
    const contactResponse = await search('contact_meta',[`meta_key=reset_code`,`meta_value=${resetCode}`]);
    if (!contactResponse.data) {
        throw new Error('Contact not found.')
    }
    return contactResponse.data;
}