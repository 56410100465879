/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 8/30/2023
 * for userx-support
 */

// ContactsForm.js
import React, {useContext, useEffect, useState} from 'react';
import {isEmail, isPhone} from "../../../_utilities/checkers";
import {addContact, sendResetCode, sendWelcome, updateContact} from "../../../_utilities/contactUtilities";
import {addCompanyContact, deleteCompanyContact, updateCompanyContact} from "../../../_utilities/companyUtilities";
import {UserContext} from "../../../_contexts/UserContext";
import supportAPI from "../../../_apis/supportAPI";

const ContactsForm = ({contacts, onContactChange, onAddContact, onEditContact, onRemoveContact}) => {
    const {userContext} = useContext(UserContext);
    const [editData, setEditData] = useState(null);
    const [newContact, setNewContact] = useState({
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
    });

    useEffect(() => {
        console.log('UserContext', userContext);
    }, []);

    const validateContact = (contact, editing = false) => {
        const {title, first_name, last_name, email, phone} = contact;

        if (title === '' || first_name === '' || last_name === '' || email === '' || phone === '') {
            return false;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailInput = document.getElementById('contact-email-input');
        if (!emailPattern.test(email)) {
            emailInput.classList.add('is-invalid');
            emailInput.setAttribute('title', 'Please enter a valid email.');
            return false;
        } else {
            emailInput.classList.remove('is-invalid');
            emailInput.setAttribute('title', '');
        }

        if (!editing) {
            const emailMatches = contacts.filter(c => c.email === email);
            if (emailMatches.length >= 1) {
                emailInput.classList.add('is-invalid');
                // Set the "title" attribute with the desired tooltip text
                emailInput.setAttribute('title', 'This email has already been used.');
                return false;
            } else {
                emailInput.classList.remove('is-invalid');
                emailInput.setAttribute('title', '');
            }
        }


        const phonePattern = /^\d{3}-\d{3}-\d{4}$/; // Assumes a 10-digit phone number
        const phoneInput = document.getElementById('contact-phone-input');
        if (!phonePattern.test(phone)) {
            phoneInput.classList.add('is-invalid');
            phoneInput.setAttribute('title', 'Please enter a valid 10 digit phone number.');
            return false
        } else {
            phoneInput.classList.remove('is-invalid');
            phoneInput.setAttribute('title', '');
        }

        return true;
    }


    const emailResetLink = async (contact) => {
        console.log('ContactsForm::emailResetCode', contact.email);
        const subject = "Account Reset from User X Support";
        const messageResponse = await supportAPI.get(`/contact/${contact.contact_id}/sendresetcode`);
        console.log('ContactsForm::emailResetLink', messageResponse.data);
        if(messageResponse.data) {
            alert("Here's your reset link:  " + messageResponse.data);
        }
    }

    return (<>
        <table className='text-nowrap list-table border bg-white w-100 mb-3 pb-3'>
            <thead>
            <tr className='bold'>
                <th className={`px-2`}>Title</th>
                <th className={`px-2`}>First Name</th>
                <th className={`px-2`}>Last Name</th>
                <th className={`px-2`}>Email</th>
                <th className={`px-2`}>Phone</th>
                <th className={`px-2`}></th>
            </tr>
            </thead>
            <tbody>
            {contacts.map((contact, index) => {
                if (editData && editData.contact_id === contact.contact_id) {
                    return (
                        <tr>
                            <td className={`px-2`}>
                                <input style={{width: '100%'}}
                                       className={`form-control`}
                                       type="text"
                                       name="title"
                                       placeholder="Title"
                                       value={editData.title}
                                       onChange={(e) => setEditData((prev) => ({...prev, title: e.target.value}))}
                                />
                            </td>
                            <td className={`px-2`}>
                                <input style={{width: '100%'}}
                                       className={`form-control`}
                                       type="text"
                                       name="first_name"
                                       placeholder="First Name"
                                       value={editData.first_name}
                                       onChange={(e) => setEditData((prev) => ({
                                           ...prev,
                                           first_name: e.target.value
                                       }))}
                                />
                            </td>
                            <td className={`px-2`}>
                                <input style={{width: '100%'}}
                                       className={`form-control`}
                                       type="text"
                                       name="last_name"
                                       placeholder="Last Name"
                                       value={editData.last_name}
                                       onChange={(e) => setEditData((prev) => ({...prev, last_name: e.target.value}))}
                                />
                            </td>
                            <td className={`px-2`}>
                                <input style={{width: '100%'}}
                                       className={`form-control`}
                                       id="contact-email-input"
                                       type="email"
                                       name="email"
                                       placeholder="E-mail"
                                       value={editData.email}
                                       onChange={(e) => setEditData((prev) => ({...prev, email: e.target.value}))}
                                />
                            </td>
                            <td className={`px-2`}>
                                <input style={{width: '100%'}}
                                       className={`form-control`}
                                       id="contact-phone-input"
                                       type="phone"
                                       name="phone"
                                       placeholder="Phone"
                                       value={editData.phone}
                                       onChange={(e) => {
                                           const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                           const areaCode = input.substring(0, 3);
                                           const firstPart = input.substring(3, 6);
                                           const secondPart = input.substring(6, 10);

                                           if (input.length > 6) {
                                               e.target.value = `${areaCode}-${firstPart}-${secondPart}`;
                                           } else if (input.length > 3) {
                                               e.target.value = `${areaCode}-${firstPart}`;
                                           } else if (input.length > 0) {
                                               e.target.value = `${areaCode}`;
                                           }
                                           setEditData((prev) => ({...prev, phone: e.target.value}))
                                       }}
                                />
                            </td>
                            <td className={`px-2`}>
                                <button disabled={!validateContact(editData, true)}
                                        className={`btn btn-small btn-success me-2`}
                                        onClick={() => {
                                            onEditContact(editData);
                                            setEditData(null);
                                        }}>Save
                                </button>
                                <button className={`btn btn-small btn-danger`}
                                        onClick={() => setEditData(() => null)}
                                >Cancel
                                </button>
                            </td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={contact.contact_id ? contact.contact_id : index}>
                            <td className={`px-2`}>{contact.title}</td>
                            <td className={`px-2`}>{contact.first_name}</td>
                            <td className={`px-2`}>{contact.last_name}</td>
                            <td className={`px-2 border-red`}>{contact.email}</td>
                            <td className={`px-2`}>{contact.phone}</td>
                            <td className={`px-2`}>
                                <button className={`btn btn-warning me-2`}
                                        onClick={() => setEditData(() => contact)}>Edit
                                </button>
                                <button className={`btn btn-danger`}
                                        onClick={() => onRemoveContact(contact.email)}>Remove
                                </button>
                                {userContext.contact && userContext.contact.group_id === "1" ?
                                    <button className={`btn btn-small btn-success ms-2`}
                                       onClick={()=>emailResetLink(contact)}
                                       >Send Reset</button>
                                    : null}
                            </td>
                        </tr>
                    );
                }
            })}
            </tbody>
            <tfoot>
            <tr>
                <td className={`px-2`}>
                    <input style={{width: '100%'}}
                           className={`form-control`}
                           type="text"
                           name="title"
                           placeholder="Title"
                           value={newContact.title}
                           onChange={(e) => setNewContact((prev) => ({...prev, title: e.target.value}))}
                    />
                </td>
                <td className={`px-2`}>
                    <input style={{width: '100%'}}
                           className={`form-control`}
                           type="text"
                           name="first_name"
                           placeholder="First Name"
                           value={newContact.first_name}
                           onChange={(e) => setNewContact((prev) => ({...prev, first_name: e.target.value}))}
                    />
                </td>
                <td className={`px-2`}>
                    <input style={{width: '100%'}}
                           className={`form-control`}
                           type="text"
                           name="last_name"
                           placeholder="Last Name"
                           value={newContact.last_name}
                           onChange={(e) => setNewContact((prev) => ({...prev, last_name: e.target.value}))}
                    />
                </td>
                <td className={`px-2`}>
                    <input style={{width: '100%'}}
                           className={`form-control`}
                           id="contact-email-input"
                           type="email"
                           name="email"
                           placeholder="E-mail"
                           value={newContact.email}
                           onChange={(e) => setNewContact((prev) => ({...prev, email: e.target.value}))}
                    />
                </td>
                <td className={`px-2`}>
                    <input style={{width: '100%'}}
                           className={`form-control`}
                           id="contact-phone-input"
                           type="phone"
                           name="phone"
                           placeholder="Phone"
                           value={newContact.phone}
                           onChange={(e) => {
                               const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                               const areaCode = input.substring(0, 3);
                               const firstPart = input.substring(3, 6);
                               const secondPart = input.substring(6, 10);

                               if (input.length > 6) {
                                   e.target.value = `${areaCode}-${firstPart}-${secondPart}`;
                               } else if (input.length > 3) {
                                   e.target.value = `${areaCode}-${firstPart}`;
                               } else if (input.length > 0) {
                                   e.target.value = `${areaCode}`;
                               }
                               setNewContact((prev) => ({...prev, phone: e.target.value}))
                           }}
                    />
                </td>
                <td className={`px-2`}>
                    <button disabled={!validateContact(newContact)}
                            className={`btn btn-small btn-success w-100`}
                            onClick={() => {
                                onAddContact(newContact);
                                setNewContact({
                                    title: '',
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    phone: ''
                                });
                            }}>Add
                    </button>
                </td>
            </tr>
            </tfoot>
        </table>
    </>);
};

export default ContactsForm;
