/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 8/30/2023
 * for userx-support
 */

import React, {useState} from 'react';
import CompanyForm from "./CompanyForm";
import ContactsForm from "./ContactsForm";
import {addCompany, addCompanyContact} from "../../../_utilities/companyUtilities";
import {search} from "../../../_utilities/reactUtils";
import {addContact, sendWelcome} from "../../../_utilities/contactUtilities";

const RegistrationForm = () => {
        const [errorMsg, setErrorMsg] = useState('');
        const [step, setStep] = useState(1);
        const [companyInfo, setCompanyInfo] = useState({name: '', url: '', description: ''});
        const [contacts, setContacts] = useState([]);

        const handleCompanyInfoChange = (e) => {
            const {name, value} = e.target;
            setCompanyInfo((prevInfo) => ({...prevInfo, [name]: value}));
        };

        const handleContactChange = (e, index) => {
            const {name, value} = e.target;
            setContacts((prevContacts) =>
                prevContacts.map((contact, i) =>
                    i === index ? {...contact, [name]: value} : contact
                )
            );
        };

        const handleAddContact = (newContact) => {
            console.log('RegistrationForm::handleAddContact', newContact);
            setContacts([...contacts, newContact]);
        };

        const handleRemoveContact = (index) => {
            setContacts(contacts.filter((contact, i) => i !== index));
        };

        const handleNextStep = () => {
            const nextStep = step + 1;
            setStep(() => nextStep);
        };

        const handlePrevStep = () => {
            const nextStep = step - 1;
            setStep(() => nextStep);
        }

        const validateCompany = () => {
            if (companyInfo.name !== '' && companyInfo.url !== '') {
                return true;
            }
            return false;
        }

        const validateContacts = () => {
            if (contacts.length >= 1) {
                return true;
            }
            return false;
        }

        const save = async () => {
            try {
                const newCompany = await addCompany(companyInfo);

                const promises = contacts.map(async (contact) => {
                    const existingContact = await search(
                        'contact',
                        [`email='${contact.email}'`],
                        'AND',
                        null,
                        true
                    );

                    if (!existingContact) {
                        // CREATE NEW CONTACT
                        const newContact = await addContact({
                            first_name: contact.first_name,
                            last_name: contact.last_name,
                            email: contact.email,
                            phone: contact.phone,
                            group_id: 5,
                            password: Date.now().toString(36) + Math.random().toString(36).substring(2),
                        });

                        await addCompanyContact({
                            company_id: newCompany.company_id,
                            contact_id: newContact.contact_id,
                            title: contact.title,
                        });

                        await sendWelcome(newContact.contact_id);
                        console.log(`Sent welcome to contact #${newContact.contact_id}.`);
                    }
                });

                await Promise.all(promises);
            } catch (err) {
                console.error(err);
                switch (err.response.status) {
                    case 422:
                        setErrorMsg('A company with this URL already exists.');
                        break;
                    default:
                        setErrorMsg(err.message);
                }
            }
        };

        const renderStep = () => {
            switch (step) {
                case 1:
                    return (<>
                        <p className={`text-center`}>Please fill out the form below to register.</p>
                        <CompanyForm companyInfo={companyInfo} onChange={handleCompanyInfoChange}/>
                        <button
                            disabled={!validateCompany()}
                            className={`btn btn-primary d-block mx-auto`}
                            type="button"
                            onClick={handleNextStep}>
                            Continue to Contacts
                        </button>
                    </>);
                case 2:
                    return (<>
                        <p className={`text-center`}>You must have at least one contact in order to register.</p>
                        <ContactsForm
                            contacts={contacts}
                            onContactChange={handleContactChange}
                            onAddContact={handleAddContact}
                            onRemoveContact={handleRemoveContact}
                        />
                                <div className="d-flex justify-content-center align-items-center">
                                    <button
                                        className={`d-block btn btn-primary d-block mx-2`}
                                        type="button"
                                        onClick={handlePrevStep}>
                                        Back to Company Info
                                    </button>
                                    <button
                                        disabled={!validateContacts()}
                                        className={`btn btn-primary d-block mx-2`}
                                        type="button"
                                        onClick={() => save().then(handleNextStep)}>
                                        Save Company
                                    </button>
                                </div>
                    </>);
                case 3:
                    return (
                        <div className={`text-center mx-auto`} style={{width: '90%', maxWidth: '650px'}}>
                            <h2>Welcome to User X Support!</h2>
                            <p><strong>Congratulations, {companyInfo.name}!</strong> We are so happy that you chose User X
                                Support.</p>
                            <p>Emails have been sent out to all of your contacts with links for setting up passwords. Once
                                you have your password set you can log into User X Support and start adding tickets.</p>
                            <p><strong>We look forward to seeing you there!</strong></p>
                        </div>
                    );
                default:
                    return null;
            }
        };

        return (
            <div className={`py-2 px-3 m-3 mx-auto`} style={{width: '90%', maxWidth: '1180px'}}>
                <h2 className={`text-center`}>Register for User X Support</h2>
                <h4 className={`text-center`} style={{color: '#999'}}>
                    <span className={step === 1 ? `text-dark` : ''}>Company Info</span>
                    &nbsp;&nbsp;<i className={`bi bi-arrow-right`}></i>&nbsp;&nbsp;
                    <span className={step === 2 ? `text-dark` : ''}>Contacts</span>
                    &nbsp;&nbsp;<i className={`bi bi-arrow-right`}></i>&nbsp;&nbsp;
                    <span className={step === 3 ? `text-dark` : ''}>Welcome</span>
                </h4>
                <hr/>
                {errorMsg !== '' ? <div className={`alert alert-danger`} role={`alert`}>{errorMsg}</div> : null}
                {renderStep()}
            </div>
        );
    }
;

export default RegistrationForm;
