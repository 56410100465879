/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/26/2022
 * for support.userx.co
 *
 * todo: WHY? WHY? WHY doesn't the FetchActivityByTicket get the lastest records when called from handleSubmit?!
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button} from "react-bootstrap";
import {formatDate} from "../../_utilities/formattingUtils";
import {UserContext} from "../../_contexts/UserContext";
import {addTime, fetchTicketActivity} from "../../_utilities/ticketUtils";
import {addActivity, emailTaggedUsers} from "../../_utilities/activityUtilities";
import {getUID} from "bootstrap/js/src/util";
import ReactMarkdown from 'react-markdown';
import useInterval from "../../_hooks/useInterval";
import supportAPI from "../../_apis/supportAPI";
import {addNotification} from "../../_utilities/notificationUtilities";
import TagPopup from "./TagPopup";
import {sendActivity} from "../../_utilities/contactUtilities";
import MarkdownInput from "./formElements/MarkdownInput";
import SelectorInput from "./formElements/SelectorInput";

const ActivityFeed = ({ticket, agentList, openHandler, changeHandler}) => {
    const {userContext} = useContext(UserContext);
    const [activity, setActivity] = useState([]);
    const [showAdd, setShowAdd] = useState(true);
    const [showTagPopup, setShowTagPopup] = useState(false);
    const [addNote, setAddNote] = useState('');
    const [addNoteTags, setAddNoteTags] = useState([]);
    const [showTime, setShowTime] = useState(false);
    const [timeContact, setTimeContact] = useState(ticket.assignee_id ? ticket.assignee_id : userContext.contact.contact_id);
    const [timeNote, setTimeNote] = useState('');
    const [timeHour, setTimeHour] = useState(0);
    const [timeMin, setTimeMin] = useState(0);

    const activityInputRef = useRef(null);

    useInterval(() => {
        try {
            fetchTicketActivity(ticket.ticket_id).then(response => {
                setActivity(() => response);
            });
        } catch (err) {
            console.error('FAIL', err);
        }
    });

    useEffect(()=>{
        if(showTime){
            if(openHandler) openHandler(true);
        } else {
            if(openHandler) openHandler(false);
        }
    },[showTime])

    useEffect(() => {
        if (changeHandler) changeHandler();
    }, [activity]);

    const handleAddChange = (value) => {
        if (value.charAt(value.length - 1) === '@') {
            setShowTagPopup(true);
        } else {
            setAddNote(value);
            // Clean up tags
            const filteredTags = addNoteTags.filter(tag => {
                const tagString = `*@${tag.name}*`;
                return value.includes(tagString);
            });

            setAddNoteTags(filteredTags);
        }
    }
    const handleAddSubmit = () => {
        if (!addNote || addNote === '') {
            console.error('Ticket:handleAddSubmit - Input out of range.');
            return;
        }
        try {
            const activity = {
                ticket_id: ticket.ticket_id,
                contact_id: userContext.contact.contact_id,
                note: addNote
            };

            if (addNoteTags.length >= 1) {
                addNoteTags.map(contact => {
                    console.log('ActivityFeed::handleAddSubmit::sendActivity',contact);
                    sendActivity(contact.id, `<p>Hello ${contact.name},</p>
<p>There has been activity on <strong><a href="https://support.userx.co/clients/${ticket.company_id}/tickets/${ticket.ticket_id}">ticket #${ticket.ticket_id}</a> - ${ticket.title}:</strong></p>
<p>${addNote}</p>
<p><strong>DO NOT REPLY</strong> to this email. Please visit <a href="https://support.userx.co/clients/${ticket.company_id}/tickets/${ticket.ticket_id}">User X Support</a> to respond. Thanks.</p>
<p>Have a great day from the User X Support Team.</p>`).then(response => {
                        console.log('ActivityFeed::handleAddSubmit::sendActivity::response',response);
                        setAddNoteTags(()=>[]);
                    });
                })
            }
            addActivity(activity)
                .then(response => fetchTicketActivity(response.ticket_id))
                .then(response => {
                        setActivity(() => response);
                        if (ticket.contact_id !== userContext.contact.contact_id) {
                            console.log('ActivityFeed - Notifying company contact');
                            addNotification({
                                contact_id: ticket.contact_id.toString(),
                                ticket_id: ticket.ticket_id.toString(),
                                note: `Activity on ticket #${ticket.ticket_id}: ${addNote.substring(0, 100)}...`
                            }).then(() => {
                            });
                        }
                        if (ticket.assignee_id && ticket.assignee_id !== userContext.contact.contact_id) {
                            console.log('ActivityFeed - Notifying assigned Agent');
                            addNotification({
                                contact_id: ticket.assignee_id.toString(),
                                ticket_id: ticket.ticket_id.toString(),
                                note: `Activity on ticket #${ticket.ticket_id}: ${addNote.substring(0, 100)}...`
                            }).then(() => {
                            });
                        }
                    }
                );
        } catch (err) {
            console.error('FAIL', err);
        }
        setAddNote('');
    }

    const handleTimeChange = () => {
        setTimeHour(document.getElementById('time-hour').value);
        setTimeMin(document.getElementById('time-min').value);
    }
    const handleTimeSubmit = () => {
        try {
            const totalSteps = (timeHour * 60 / 6) + (timeMin / 6);
            const timeData = {
                ticket_id: ticket.ticket_id,
                contact_id: timeContact,
                time: totalSteps.toString(),
                description: timeNote
            };

            addTime(timeData).then(response => fetchTicketActivity(response.ticket_id)).then(response => {
                setActivity(() => response);
                if (ticket.contact_id !== userContext.contact.contact_id || ticket.assignee_id !== userContext.contact.contact_id) {
                    addNotification({
                        contact_id: ticket.contact_id.toString(),
                        ticket_id: ticket.ticket_id.toString(),
                        note: `Time added to ticket #${ticket.ticket_id}`
                    }).then(() => {
                    });
                }
                setTimeHour(()=>0);
                setTimeMin(()=>0);
                setTimeNote(()=>'');
            });
        } catch (err) {
            console.error('FAIL', err);
        }
        setShowTime(false);
        setShowAdd(true);

    }

    const trashClickHandler = (e) => {
        console.log('trashClickHandler', parseInt(e.target.getAttribute('data-target')));
        if (window.confirm('This will permanently delete the activity from the feed. Are you sure?')) {
            supportAPI.delete(`/activity/${parseInt(e.target.getAttribute('data-target'))}`).then(r => console.log(r));
        }
    }

    const tagPopupClickHandler = (e) => {
        console.log('ActivityFeed::tagPopupClickHandler',e);
        if(!e.target){
            console.error('No tag selected');
        }
        if (e.target.innerHTML === 'Cancel Tag') {
            setShowTagPopup(false);
            return;
        }

        setAddNote((note) => note + ` *@${e.target.dataset.name}* `);
        setAddNoteTags((tags) => [...addNoteTags, {
            id: e.target.id,
            name: e.target.dataset.name,
            email: e.target.dataset.email
        }]);
        setShowTagPopup(false);
        const activityNote = document.getElementById('add-activity-note');
        setShowTagPopup(false);
        activityInputRef.current.nodeMdText.current.focus();
    }

    return (
        <div className='activity-wrapper d-flex flex-column h-100'>
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className=''><i className={`bi bi-chat-right-dots`}/> Recent activity...</h4>
                <span>
                    {userContext.contact.group_id <= 4 ? !showTime ? <i className='bi bi-clock mx-2' onClick={() => {
                            setShowAdd(false);
                            setShowTime(true);
                        }}></i>
                        : <i className='bi bi-dash-circle-fill mx-2' onClick={() => {
                            setShowTime(false);
                            setShowAdd(true)
                        }}></i> : null}
                    </span>
            </div>
            {showAdd ? (
                <>
                    <div className='d-flex position-relative align-content-stretch'>
                        {showTagPopup ? <TagPopup ticket={ticket} agentList={agentList} clickHandler={tagPopupClickHandler}/> : null}
                        <MarkdownInput forwardedRef={activityInputRef} value={addNote}
                                       changeHandler={(text) => handleAddChange(text)}
                                       height='150px' styles={{flexGrow: "1"}}/>
                        <Button className='border-0 bg-success ms-2' style={{width: '188px'}} onClick={handleAddSubmit}>Submit
                            Activity</Button>
                    </div>
                    <hr/>
                </>
            ) : null}
            {userContext.contact.group_id <= 4 && showTime ? (
                <>
                    <div className='d-flex align-content-stretch'>
                        <div className='d-flex flex-column flex-grow-1 me-3'>
                                <textarea id='time-note' className='form-control mb-3 p-3 flex-grow-1'
                                          placeholder='Describe completed work...'
                                          value={timeNote}
                                          onChange={(e) => setTimeNote(e.target.value)}
                                >{addNote}</textarea>
                            <div className='d-flex justify-content-between text-nowrap'>
                                <label className='flex-grow-1 me-3'>
                                    <SelectorInput inputClass={`mb-3`}
                                                   inputName={`time_contact`}
                                                   inputValue={timeContact}
                                                   inputOptions={agentList && agentList.map ? agentList.map(item => {
                                                       return {
                                                           label: `${item.first_name} ${item.last_name}`,
                                                           value: item.contact_id
                                                       }
                                                   }) : null}
                                                   onChange={(value) => setTimeContact(() => value)}
                                    />
                                </label>
                                <label htmlFor='time-hour' className='me-3'>
                                    <input type='number' id='time-hour' name='time-hour' className='form-control'
                                           placeholder='hour' value={timeHour} onChange={handleTimeChange} min={0}
                                           max={12}
                                    />
                                </label>
                                <label htmlFor='time-hour' className=''>
                                    <input type='number' id='time-min' name='time-min' className='form-control'
                                           placeholder='min' value={timeMin} onChange={handleTimeChange} min={0}
                                           max={54} step={6}
                                    />
                                </label>
                            </div>
                        </div>
                        <Button className='border-0 bg-success' style={{width: '150px'}}
                                onClick={handleTimeSubmit}>Submit Time</Button>
                    </div>
                    <hr/>
                </>
            ) : null}
            <div className='activity-feed flex-grow-1 d-flex flex-column overflow-auto' style={{maxHeight: '500px'}}>
                {activity && activity.map && activity.length >= 1 ? activity.map((item) => {
                    return (
                        <div key={`activity-${item.activity_id}`} id={item.activity_id}
                             className='activity-feed-item position-relative'>
                            {userContext.contact.group_id === '1' ?
                                <i className='position-absolute bi bi-trash' data-target={item.activity_id}
                                   style={{top: '0px', right: '10px'}} onClick={trashClickHandler}></i> : null}
                            {item.contact_id >= 1 ? <div>
                                    <strong>{item.contact_name}</strong> on {formatDate(item.date_created, true, 3)} said:
                                </div>
                                :
                                <div><strong>User X Support</strong> on {formatDate(item.date_created, true, 3)}:</div>}
                            <ReactMarkdown>{item.note}</ReactMarkdown>
                            <hr/>
                        </div>);
                }) : 'No Activity yet.'}
            </div>
        </div>
    );
};

export default ActivityFeed;




