/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 6/2/2023
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {UserContext} from "../_contexts/UserContext";
import {GET_CLIENTS, waveAPI} from "../_apis/waveAPI";
import SelectorInput from "./_ui/formElements/SelectorInput";
import {TICKET_TYPES} from "../system/defaults";
import {gql} from "@apollo/client";
import supportAPI from "../_apis/supportAPI";
import {formatCurrency, formatDate} from "../_utilities/formattingUtils";
import {fetchContact} from "../_utilities/contactUtilities";
import {generateInvoiceSummary} from "../_apis/openChatAPI";

const AdminInvoices = () => {
    const params = useParams();
    const {userContext} = useContext(UserContext);
    const agentId = params.agentId ? params.agentId : userContext.contact ? userContext.contact.contact_id : null;
    const [companies, setCompanies] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [agents, setAgents] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({company: {id: null, name: null}, items: [], summary:''});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [currentInvoiceItems, setCurrentInvoiceItems] = useState([]);

    useEffect(() => {
        collectData().then(response => {
            console.log('AdminInvoices::data', response);
            setCurrentInvoiceItems(() => response.invoiceItems);
            setCompanies(()=> response.uniqueCompanies);
            setAgents(()=>response.agentDetails);
        });

        // supportAPI.get(`/company/-1/prevmonthtime/1`).then(response => {
        //     const uniqueCompanies = Object.values(response.data.reduce((acc, item) => {
        //         const key = `${item.company_id}-${item.company}`;
        //         if (!acc[key]) {
        //             acc[key] = {id: item.company_id, name: item.company};
        //         }
        //         return acc;
        //     }, {}));
        //     console.log('uniqueCompanies', uniqueCompanies);
        //     setCurrentInvoiceItems(() => response.data)
        //     setCompanies(() => uniqueCompanies);
        // });


        waveAPI.query({
            query: gql`
query {
  business(id:"QnVzaW5lc3M6YThkYWZiY2UtMmQxYS00ZmJiLWE0MTEtYWZmOGIxZjU5Yzli"){
    customers(sort:NAME_ASC pageSize:300){
      edges{
        node{
          id
          name
          firstName
          lastName
          email
          outstandingAmount{
            value
          }
        }
      }
    }
  }
}
`
        }).then(results => {
            // console.log('AdminInvoices::customers', results.data.business.customers.edges);
            setCustomers(results.data.business.customers.edges.map(item => item.node));

        });
    }, []);

    useEffect(() => {
        if (selectedCustomer) {
            waveAPI.query({
                query: gql`
                    query {
                      business(id:"QnVzaW5lc3M6YThkYWZiY2UtMmQxYS00ZmJiLWE0MTEtYWZmOGIxZjU5Yzli") {
                        name
                        invoices(sort:[INVOICE_DATE_DESC] customerId:"${selectedCustomer.id}"){
                          edges{
                            node{
                                id
                                invoiceDate
                                invoiceNumber
                                items{
                                  product{
                                    name
                                  }
                                  description
                                  total{
                                    value
                                  }
                                }
                                amountDue{value}
                                amountPaid{value}
                            }
                          }
                        }
                      }
                    }
                `
            }).then(results => {
                console.log('AdminInvoices::invoices', results.data.business.invoices.edges);
                setInvoices(results.data.business.invoices.edges.map(item => item.node));
            });
        }
    }, [selectedCustomer]);

    useEffect(() => {
        if (invoices.length > 0) {
            console.log('AdminInvoices::invoices', invoices);
        }
    }, [invoices]);


    return (
        <div>
            <div className={`row m-3 p-3`}>
                <div className={`col-xs-12 col-sm-6 col-md-4`}>
                    <h2>Agent Payroll</h2>
                    {agents ? <ul className={`m-3`}>{agents.map(renderAgentDetails)}</ul> : null}
                    <h2>Companies</h2>
                    {companies ? <ul className={`m-3`}>{companies.map(renderCompany)}</ul> : null}
                </div>
                <div className={`col-xs-12 col-sm-6 col-md-8`}>
                    <h2>{selectedCompany.company.id ? selectedCompany.company.name : 'All Invoice Items'}</h2>
                        <h3>Invoice Details</h3>
                    {selectedCompany.company.id ? <p><strong>Summary</strong><br/>{selectedCompany.summary}</p> : null}
                    {selectedCompany.company.id ? (
                        <p>
                            <strong>Items</strong><br/>
                            <ul>
                                {selectedCompany.items ?
                                    selectedCompany.items.map(i => renderDetail(i)) :
                                    currentInvoiceItems && currentInvoiceItems.map(i => renderDetail(i))
                                }
                            </ul>
                        </p>
                    ) : (
                        'No items to display.'
                    )}<h4>Monthly Total: {getCurrentTotal()}</h4>
                </div>
            </div>
        </div>
    );

    async function collectData(){
        const uniqueCompanies = [];
        const agentDetails = [];

        const response = await supportAPI.get(`/company/-1/prevmonthtime/${params.offset ? params.offset : 0}`);
        const invoiceItems = response.data;
        for(const obj of invoiceItems) {
            const {company_id, company, agent, agent_payrate} = obj;

            // Check if the company exists in uniqueCompanies array
            const existingCompany = uniqueCompanies.find(c => c.id === company_id);
            if (!existingCompany) {
                uniqueCompanies.push({id: company_id, name: company});
            }

            let existingAgent = agentDetails.find((agent) => agent.name === obj.agent);
            if(!existingAgent){
                existingAgent = {name:agent, rate:parseInt(agent_payrate), total: 0};
                agentDetails.push(existingAgent);
            }
            existingAgent.total += parseInt(obj.time) * existingAgent.rate/10;

        };

        return {invoiceItems,uniqueCompanies,agentDetails};
    }

    function renderInvoice(invoice) {
        return <li><strong>{invoice.invoiceNumber}</strong>: {invoice.invoiceDate} Amount
            Due: {formatCurrency(invoice.amountDue.value)}... Amount Paid: {formatCurrency(invoice.amountPaid.value)}
        </li>
    }

    function renderAgentDetails(agent) {
        return <li key={agent.name}>{agent.name} - ${agent.total}</li>;
    }

    function renderCompany(company) {
        return <li key={company.id} className={`mb-2`} data-id={company.id}
                   onClick={companyChangeHandler}>{company.name}</li>
    }

    function renderDetail(detail) {
        return <li
            key={detail.date}>{formatDate(detail.date)} - {detail.description} - {formatCurrency(detail.time*10)}</li>
    }

    function compareNames(a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }

    async function companyChangeHandler(e) {
        const company = companies.find(c => parseInt(c.id) === parseInt(e.target.dataset.id));
        const items = currentInvoiceItems.filter(i => i.company_id === e.target.dataset.id);
        const summary = await generateInvoiceSummary(items);
        setSelectedCompany({company, items, summary});
    }

    function getCurrentDetails(companyId = -1) {
        console.log('AdminInvoices::getCurrentDetails', selectedCompany);
        supportAPI.get(`company/${companyId}/prevmonthtime/1`).then(response => {
            if (response.data) {
                if (companyId > 0) {
                    setCurrentInvoiceItems(response.data);
                }
            }
        })
    }

    function getCurrentTotal() {
        if (currentInvoiceItems.length === 0) {
            return formatCurrency(0);
        }
        let total = currentInvoiceItems.reduce((acc, obj) => acc + parseInt(obj.time*10), 0);
        if(selectedCompany.company.id){
            total = selectedCompany.items.reduce((acc, obj) => acc + parseInt(obj.time*10), 0);
        }
        return formatCurrency(total);
    }
};

export default AdminInvoices;