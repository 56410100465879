/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 3/1/2023
 * for userx-support
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import FileInfo from "./FileInfo";
import supportAPI from "../../_apis/supportAPI";
import useInterval from "../../_hooks/useInterval";
import {addActivity} from "../../_utilities/activityUtilities";
import {fetchTicketActivity} from "../../_utilities/ticketUtils";
import {addNotification} from "../../_utilities/notificationUtilities";
import {UserContext} from "../../_contexts/UserContext";

const Attachments = ({targetType = null, target = null, classes = ''}) => {
    const {userContext} = useContext(UserContext);
    const [addAttachment, setAddAttachment] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [files, setFiles] = useState([]);
    const [link, setLink] = useState('');

    useEffect(refresh, []);
    useInterval(refresh, 1000);

    return (
        <div id='attach-files' className={classes}>
            <div className={`d-flex justify-content-between align-items-center`}>
                <h4 className={`pe-3 me-3`}><i className={`bi bi-paperclip`}/>Attachments...</h4>
                <div id='file-attachment-add'>
                    <i className='bi bi-plus-circle-fill text-success' style={{fontSize: '30px'}}
                       onClick={() => setAddAttachment(() => !addAttachment)}/>
                    {addAttachment ? <div id='file-attachment-tool' className=' bg-white shadow-lg p-3 rounded'>
                        <i className='bi bi-x-circle-fill text-secondary'
                           style={{fontSize: '30px', position: 'absolute', top: '0', right: '5px'}}
                           onClick={() => setAddAttachment(() => !addAttachment)}/>
                        <strong>Attach File</strong>
                        <form method="POST" encType='multipart/form-data' onSubmit={submitHandler}>
                            <input type='file' name='file-attachment' multiple
                                   accept='text/csv,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,app,application/pdf,application/doc,image/png,image/jpg,image/jpeg,image/gif'
                                   className='form-control mb-3'
                                   onChange={handleFileChange}/>
                            {files.length === 0 ?
                                <input type='text' onPaste={handlePaste} className='p-3 text-center w-100'
                                       placeholder='Paste attachment here'
                                       style={{
                                           backgroundColor: '#eeeeff',
                                           border: '1px solid #ccccff',
                                           color: '#6666ff'
                                       }}/>
                                : files.map(file => <li key={file.name}>${file.name}</li>)}
                            <input type='submit' className='btn btn-success w-100 mt-3'/>
                        </form>
                    </div> : null}

                </div>
            </div>
            <div className={`d-flex justify-content-start flex-wrap w-100`}>
                {attachments.map && attachments.length > 0 ? attachments.map(a => <FileInfo key={a.attachment_id} file={a}/>) : null}
            </div>
        </div>
    );

    function handleFileChange(e) {
        setFiles(Object.values(e.target.files));
    }

    function handleLinkChange(e) {
        if (e.target.value !== undefined && e.target.value !== '') {
            setLink(e.target.value);
        }
    }

    function handlePaste(e) {
        for (let i = 0; i < e.clipboardData.items.length; i++) {
            let item = e.clipboardData.items[i];
            let name = e.clipboardData.getData('text');
            console.log('item', item);
            if (item.kind === 'file') {
                const pasted = item.getAsFile();
                const name = `pasted-${Date.now()}.png`;
                const file = new File([pasted], name, {type: pasted.type});
                setFiles((prev) => [...prev, file]);
            }
        }
    }

    function submitHandler(e) {
        e.preventDefault();
        const targetIdField = `${targetType}_id`;
        const formData = new FormData();
        files.map(file => {
            console.log('Attachments::submitHander::appendFormData', file);
            formData.append("file[]", file)
        });
        let addNote = "";
        supportAPI.post(`/${targetType}/${target[targetIdField]}/attachments`, formData).then((response) => {
            try {
                if(response.data && typeof response.data === 'string'){
                    throw new Error(response);
                }
                const images = response.data.map(a => {
                    const types = ['png', 'jpg', 'gif', 'jpeg'];
                    types.map(t => {
                        if (a.url.toLowerCase().includes(t)) {
                            addNote += `![${a.name}](${a.url})`;
                        }
                    });
                });
                if(targetType === 'ticket') {
                    addActivity({
                        ticket_id: target[targetIdField],
                        contact_id: userContext.contact.contact_id,
                        note: addNote
                    }).then(response => fetchTicketActivity(response.ticket_id)).then(response => {
                        if (target.contact_id && target.contact_id !== userContext.contact_id)
                            addNotification({
                                contact_id: target.contact_id.toString(),
                                ticket_id: target.ticket_id.toString(),
                                note: `Files attached to ticket #${target.ticket_id}`
                            }).then(() => {
                            });
                    });
                }
            } catch (err) {
                console.error('FAIL', err);
            }
            setFiles(() => []);
            setAddAttachment(() => false);
        });
    }

    function refresh() {
        if(targetType && target){
            supportAPI.get(`/${targetType}/${target[`${targetType}_id`]}/attachments`).then(response => {
                if(response.data){
                    setAttachments(() => response.data);
                } else {
                    setAttachments(()=>[]);
                }
            });
        }
    }
};

export default Attachments;