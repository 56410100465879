/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import React, {useContext} from 'react';
import {STEP_VALUE, TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../../system/defaults";
import Badge from "react-bootstrap/Badge";
import {formatCurrency, formatDate} from "../../_utilities/formattingUtils";
import {useNavigate} from "react-router";
import {UserContext} from "../../_contexts/UserContext";

const TicketItemCompleted = ({ticket})=>{
    const {userContext} = useContext(UserContext);
    const navigate = useNavigate();
    const priority = TICKET_PRIORITIES.find(i => i.id === parseInt(ticket.priority_id));
    const status = TICKET_STATUS.find(i => i.id === parseInt(ticket.status_id));
    const type = TICKET_TYPES.find(i => i.id === parseInt(ticket.type_id));
    const estimate = {
        required: parseInt(ticket.estimate_required),
        time: parseInt(ticket.estimated_time),
        approved: parseInt(ticket.estimate_approved)
    }
    const ticketClickHandler = (ticket_id) => {

    }
    return (
        <div
            className={`mb-1 p-1 border-top align-items-start`}
            id={ticket.ticket_id}
            key={ticket.ticket_id}
            onClick={() => navigate(`/clients/${ticket.contact_id}/tickets/${ticket.ticket_id}`)} >
                <span className='me-3'>
                    {priority ?
                        <Badge className='me-1' bg={priority.color}>{priority.name}</Badge> : null}
                    {type ? <Badge bg="secondary" title="type">{type.name}</Badge> : null}
                </span>
                <div>
                    <div className="px-1 me-2"><strong>{ticket.title}</strong></div>
                    <div className='small text-nowrap'>Completed on {formatDate(ticket.date_completed)} for {ticket.time ? formatCurrency(parseInt(ticket.time) * STEP_VALUE) : 'FREE'}</div>
                </div>
        </div>
    );
};

export default TicketItemCompleted;