/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {search} from "../_utilities/reactUtils";
import {UserContext, useUser} from "../_contexts/UserContext";
import {DashboardContext, useDashboard} from "../_contexts/DashboardContext";
import StatBlock from "./_ui/StatBlock";
import ListBlock from "./_ui/ListBlock";
import TicketItem from "./tickets/TicketItem";
import {formatCurrency} from "../_utilities/formattingUtils";
import {getUID} from "bootstrap/js/src/util";
import useInterval from "../_hooks/useInterval";
import supportAPI from "../_apis/supportAPI";
import {STEP_VALUE} from "../system/defaults";
import {useNavigate} from "react-router";
import CompanySelector from "./_ui/CompanySelector";
import {ListModeSelector} from "./_ui/ListModeSelector";
import TableBlock from "./_ui/TableBlock";

const AdminDashboard = () => {
    const {isRefreshing} = useDashboard();
    const navigate = useNavigate();
    const [masterList, setMasterList] = useState([]);
    const [backlog, setBacklog] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [inProgress, setInProgress] = useState([]);
    const [testing, setTesting] = useState([]);
    const [monthTotal, setMonthTotal] = useState(0);
    const [ytdTotal, setYtdTotal] = useState(0);
    const [companyList, setCompanyList] = useState();
    const [selectedCompany, setSelectedCompany] = useState(-1);
    const [listMode, setListMode] = useState('list');

    useEffect(() => {
        console.log('masterList',masterList);
        console.log('backlog',backlog);
        console.log('assigned',assigned);
        console.log('inProgress',inProgress);
        console.log('testing',testing);
    }, [masterList]);


    const getOffset = (timeZone = 'UTC', date = new Date()) => {
        const utcDate = new Date(date.toLocaleString('en-US', {timeZone: 'UTC'}));
        const tzDate = new Date(date.toLocaleString('en-US', {timeZone}));
        return (tzDate.getTime() - utcDate.getTime()) / 6e4;
    }

    useInterval(refresh, 5000, isRefreshing);

    return (
        <div className={`component-container d-flex flex-column h-100`}>
            <div className={`d-flex border-bottom flex-wrap`}>
                <StatBlock title={`Time to Assignment`} number={100}
                           classes={`border-end border-bottom bg-secondary text-white`}
                           style={{minWidth: '200px'}}/>
                <StatBlock title={`Time to Completion`} number={100}
                           classes={`border-end border-bottom bg-secondary text-white`}
                           style={{minWidth: '200px'}}/>
                <StatBlock title={`${new Date().toLocaleString("default", {month: "long"})} Income`}
                           number={formatCurrency(monthTotal)}
                           classes={`border-end border-bottom bg-secondary text-white`} style={{minWidth: '200px'}}/>
                <StatBlock title={`YTD Income`} number={formatCurrency(ytdTotal)}
                           classes={` border-bottom bg-secondary text-white`}
                           style={{minWidth: '200px'}}/>
            </div>
            <div className={`d-flex`}>
                <ListModeSelector selectedMode={listMode} changeHandler={(e) => setListMode(e.target.value)} />

                {companyList && companyList.length > 1 ?
                <CompanySelector companyList={companyList} selectedCompany={selectedCompany} changeHandler={(e) => e.target.value > 0 ? navigate(`/clients/${e.target.value}`) : null} />
                 : null}
            </div>
            <div className={`content-container flex-grow-1 align-items-stretch row m-3 p-3`}>
                {listMode === 'list' ? <TableBlock title={`Client Tickets`} classes={`table-mode bg-white`}
                                                   items={masterList}/> : <>
                <div className={`col-xs-12 col-sm-6 col-lg-3 mb-3`}>
                    <ListBlock title={`Backlog`}
                               items={backlog.map && backlog.length > 0 ? backlog.map(item => <TicketItem
                                   key={getUID('unassigned-')}
                                   ticket={item} showIcon={true}/>) : null}/>
                </div>
                <div className={`col-xs-12 col-sm-6 col-lg-3 mb-3`}>
                    <ListBlock title={`Assigned`}
                               items={assigned.map && assigned.length > 0 ? assigned.map(item => <TicketItem
                                   key={getUID('assigned-')}
                                   ticket={item} showIcon={true}/>) : null}/>
                </div>
                <div className={`col-xs-12 col-sm-6 col-lg-3 mb-3`}>
                    <ListBlock title={`In Progress`}
                               items={inProgress.map && inProgress.length > 0 ? inProgress.map(item => <TicketItem
                                   key={getUID('inprogress-')}
                                   ticket={item} showIcon={true}/>) : null}/>
                </div>
                <div className={`col-xs-12 col-sm-6 col-lg-3 mb-3`}>
                    <ListBlock title={`Testing`}
                               items={testing.map && testing.length > 0 ? testing.map(item => <TicketItem
                                   key={getUID('testing-')}
                                   ticket={item} showIcon={true}/>) : null}/>
                </div></>}
            </div>
        </div>
    );

    function refresh() {
        if (!companyList) {
            supportAPI.get(`/company`).then(response => {
                setCompanyList(() => response.data
                    .sort((a,b)=> {
                        if(a.name.toUpperCase() > b.name.toUpperCase()) {
                            return 1;
                        } else if(a.name.toUpperCase() < b.name.toUpperCase()) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }));
            });
        } else {
            if (!selectedCompany) {
                setSelectedCompany(() => companyList[0].company_id)
            }
        }

        search('ticket',[`company_id > 1`], 'AND', 'priority_id ASC, date_due ASC').then(response => {
            const masterList = [];
            const backlog = [];
            const assigned = [];
            const inProgress = [];
            const testing = [];

            response.forEach(ticket => {
                if (ticket.company.mode === "1") {
                    // Add ticket to master list
                    masterList.push(ticket);
                    // Categorize ticket as 'backlog'
                    const status_id = parseInt(ticket.status_id);
                    if (status_id === 1 || (ticket.assignee_id === null && status_id === 7)) {
                        backlog.push(ticket);
                    }
                    // Categorize ticket as 'assigned'
                    else if (status_id === 2 || (ticket.assignee_id !== null && status_id >= 7)) {
                        assigned.push(ticket);
                    }
                    // Categorize ticket as 'inProgress'
                    else if (status_id === 3) {
                        inProgress.push(ticket);
                    }
                    // Categorize ticket as 'testing'
                    else if (status_id === 4) {
                        testing.push(ticket);
                    }
                }
            });
            setMasterList(prev => response);
            setBacklog(prev => backlog);
            setAssigned(prev => assigned);
            setInProgress(prev => inProgress);
            setTesting(prev => testing);
        });

        // search('ticket', [
        //     `(date_due < CURDATE() OR (date_due IS NULL AND date_created < DATE_SUB(CURDATE(), INTERVAL 4 DAY)))`,
        //     `status_id < 4`,
        //     `company_id > 1`
        // ], 'AND', 'priority_id ASC, date_due ASC').then(response => {
        //     setOverdue(response.filter(t => t.company.mode === "1"))
        // });
        // search('ticket', [
        //     `(status_id = 1 || (assignee_id IS NULL && status_id = 7))`,
        //     `company_id > 1`
        // ], 'AND', 'priority_id ASC, date_due ASC').then(response => {
        //     setBacklog(response.filter(t => t.company.mode === "1"))
        // });
        // search('ticket', [
        //     `(status_id = 2 || (assignee_id IS NOT NULL && status_id >= 7))`,
        //     `company_id > 1`
        // ], 'AND', 'priority_id ASC, date_due ASC').then(response => {
        //     setAssigned(response.filter(t => t.company.mode === "1"));
        // });
        // search('ticket', [
        //     `status_id = 3`,
        //     `company_id > 1`
        // ], 'AND', 'priority_id ASC, date_due ASC').then(response => {
        //     setInProgress(response.filter(t => t.company.mode === "1"))
        // });
        // search('ticket', [
        //     `status_id = 4`,
        //     `company_id > 1`
        // ], 'AND', 'priority_id ASC, date_due ASC').then(response => {
        //     setTesting(response.filter(t => t.company.mode === "1"))
        // });

        calculateTotals();
    }

    function calculateTotals() {
        supportAPI.get(`/time/0/currenttime`).then(response => {
            if (response.data && response.data.total_time) {
                setMonthTotal(() => parseInt(response.data.total_time.toString()) * STEP_VALUE)
            }
        });
        supportAPI.get(`/time/0/ytdtime`).then(response => {
            if(response.data && response.data.total_time) {
                setYtdTotal(() => parseInt(response.data.total_time.toString()) * STEP_VALUE)
            }
        });
    }
};

export default AdminDashboard;