/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 2/27/2023
 * for userx-support
 */

import React, {useEffect, useState} from 'react';
import supportAPI from "../../_apis/supportAPI";

const FileInfo = ({targetType,target,file})=>{
    const imgTypes = ['gif','jpg','png','ai','psd'];
    const [icon, setIcon] = useState('file');

    useEffect(()=>{
        if(imgTypes.includes(file.type)){
            setIcon(()=>'image');
        } else {
            setIcon(()=>`filetype-${file.type}`);
        }
    },[file]);

    const deleteFile = () => {
        const path = `/${file.target_type}/${file.ticket_id}/attachments/${file.attachment_id}`;
        supportAPI.delete(`/${file.target_type}/${file.ticket_id}/attachments/${file.attachment_id}`);
    }

    return (
        <div className='icon-info-container me-3' >
            <i className={`icon bi bi-${icon} shadow-sm bg-white`} />
            <div className='icon-info shadow-lg pe-3'>
                <i className={`icon bi bi-${icon}`} />
                <div className='icon-info-tools p-2'>
                    {file.name}
                    <div className='icon-info-icons'>
                        {file.type === 'image' ? <i className='bi bi-search cursor-pointer' title='Preview' /> : null}
                        <a href={file.url} className='text-black' target='_blank'><i className='bi bi-box-arrow-up-right cursor-pointer' title='Download' /></a>
                        <i className='bi bi-trash cursor-pointer' title='Delete' onClick={deleteFile} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileInfo;