/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import supportAPI from "../_apis/supportAPI";
import {isInt} from "./checkers";

export const signIn = async (username, password) => {
    if (!username || !password) throw new Error('Insufficient data.');
    const loginResponse = await supportAPI.post(`/auth/login`, JSON.stringify({username, password}));
    if (!loginResponse.data) {
        throw new Error('Username or password incorrect. Try again.');
    }
    return loginResponse.data;
}

export const clearLocalStorage = async () => {
    localStorage.removeItem('uxUser');
    localStorage.removeItem('uxFilters');
    localStorage.removeItem('uxClients');
    localStorage.removeItem('uxCompanies');
    localStorage.removeItem('uxCompanyInfo');
    localStorage.removeItem('uxCompanyContacts');
    localStorage.removeItem('uxTicketList');
}

export const fetchGroupList = async (group_id = null) => {
    let contactResponse = {};
    if (Array.isArray(group_id)) {
        const tempList = [];
        for (let i = 0; i < group_id.length; i++) {
            const contactResponse = await supportAPI.post(`/search/contact`, `group_id IN [${group_id.join(',')}]`);
            if (contactResponse.data) {
                while(contactResponse.data.length > 0){
                    const contact = contactResponse.data.pop();
                    console.log(tempList,contact);
                    if(!tempList.includes(contact)){
                        tempList.push(contact);
                    }
                }
            }
        }
        contactResponse.data = tempList;
    } else if (isInt(group_id)) {
        const contactResponse = await supportAPI.post(`/search/contact`, JSON.stringify({group_id}));
    } else {
        const contactResponse = await supportAPI.get(`/contact`);
    }
    if (!contactResponse.data) {
        throw new Error('Contact(s) not found.');
    }
    return contactResponse.data;
}

