/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 8/30/2023
 * for userx-support
 */

// CompanyForm.js
import React from 'react';
import {renderAlert} from "../../../_utilities/formattingUtils";
import MarkdownInput from "./MarkdownInput";

const CompanyForm = ({ companyInfo, onChange }) => {
    return (
        <div>
            <form>
                <div className="mb-3" style={{width: '100% !important'}}>
                    <label htmlFor="name" className='w-100 '><strong>Company Name</strong>
                        <input type="text" id="name" name="name" className="form-control" placeholder={`Acme, LLC`}
                               value={companyInfo.name ? companyInfo.name : ''}
                               onChange={onChange}/>
                    </label>
                </div>
                <div className="mb-3" style={{width: '100% !important'}}>
                    <label htmlFor="url" className='w-100 '><strong>Website URL</strong>
                        <input type="text" id="url" name="url" className="form-control" placeholder={`https://www.acme.com`}
                               value={companyInfo.url ? companyInfo.url : ''}
                               onChange={onChange}/>
                    </label>
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className='w-100'><strong>Description</strong> (Uses markdown)
                        <MarkdownInput name='description' value={companyInfo.description ? companyInfo.description : ''}
                                       changeHandler={(value) => onChange({target:{name:'description',value:value}})}/>
                    </label>
                </div>
            </form>
        </div>
    );
};

export default CompanyForm;
