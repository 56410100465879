/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/21/2022
 * for support.userx.co
 */

import React, {useContext, useEffect} from 'react';
import {UserContext} from "../../_contexts/UserContext";
import {useNavigate} from "react-router";
import NotificationBell from "./NotificationBell";
import {clearLocalStorage} from "../../_utilities/authUtilities";

function Header() {
    const navigate = useNavigate();
    const {userContext, setUserContext} = useContext(UserContext);

    const dash = () => {
        navigate(userContext.dashboard);
    }

    const myTickets = () => {
        navigate(`/agents/${userContext.contact.contact_id}`);
    }

    const signOut = () => {
        setUserContext({contact: null, dashboard: null, filters: []});
        clearLocalStorage().then(() => navigate('/login'));
    }

    return (
        <div id='main-header' className="align-items-center border-bottom p-3">
            <div id='main-header-info' className='flex-grow-1 d-flex justify-content-between align-items-center'>
                <img className="d-block" height="50px"
                     src="https://www.whoisuserx.com/blog/wp-content/uploads/2015/09/int-logo-300x110.png"
                     alt="User X Logo"/>
                     <span>
                <strong
                    className={`text-secondary me-3`}>Welcome, {userContext && userContext.contact ? `${userContext.contact.first_name} ${userContext.contact.last_name}` : 'User'}!</strong>
                {userContext && userContext.contact ? <NotificationBell/> : null}
                     </span>
            </div>
            <div id='main-header-menu'>
                {userContext && userContext.dashboard ?
                    <span className={`btn btn-small btn-secondary ms-2`} onClick={dash}>Dashboard</span> : null}
                {userContext && userContext.contact && userContext.contact.group_id === "1" ?
                    <span className={`btn btn-small btn-secondary ms-2`} onClick={myTickets}>My Tickets</span> : null}
                {userContext && userContext.contact ?
                    <span className={`btn btn-small btn-secondary ms-2`} onClick={signOut}>Sign Out</span> : null}
            </div>
        </div>
    );
}

export default Header;