/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 12/4/2022
 * for userx-support
 */

import React from 'react';
import MarkdownIt from "markdown-it";
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const MarkdownInput = ({value,changeHandler,height,styles='',forwardedRef = null})=>{

    const mdParser = new MarkdownIt({
        html: false,
        linkify: true,
        typographer: true
    });

    return (
        <MdEditor style={{...styles, height: height ? height : '200px' }}
                  ref={forwardedRef}
                  value={value ? value : ''}
                  renderHTML={text => mdParser.render(text)}
                  view={{menu:true, md: true, html: false}}
                  preview="preview"
                  onChange={({text}) => changeHandler(text)}
                  onPaste={({text}) => changeHandler(text)}
        />
    );
}

export default MarkdownInput;