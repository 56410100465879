/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 2/22/2023
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import useInterval from "../../_hooks/useInterval";
import {UserContext} from "../../_contexts/UserContext";
import supportAPI from "../../_apis/supportAPI";
import {formatDate} from "../../_utilities/formattingUtils";
import {fetchTicket} from "../../_utilities/ticketUtils";
import {useNavigate} from "react-router";
import Checkbox from "./formElements/Checkbox";

const NotificationBell = () => {
    const {userContext,setUserContext} = useContext(UserContext);
    const navigate = useNavigate();
    const [notes, setNotes] = useState([]);
    const [show, setShow] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [notePrefs, setNotePrefs] = useState({sendHourly:false,sendDaily:false,sendWeekly:false});

    // initial setup
    useEffect(()=>{
        const noteMeta = userContext.contact.meta.find(m => m.meta_key === 'note_prefs');
        if(noteMeta){
            setNotePrefs(()=>JSON.parse(noteMeta.meta_value));
        }
        refresh();
    }, []);

    // timed refresh
    useInterval(refresh, 1000);

    function refresh() {
        supportAPI.get(`/contact/${userContext.contact.contact_id}/notifications`).then(notes => {
            if (notes.data) setNotes(() => notes.data);
        }).catch((err) => {
        });
    }

    function toggle() {
        setShow(() => !show);
        if (showSettings) {
            setShowSettings(() => show);
        }
    }

    function remove(e) {
        if (window.confirm('This will permanently remove this notification. Are you sure?')) {
            console.log(`NotificationBell::remove`, e.target.getAttribute('data-target'));
            supportAPI.delete(`/notifications/${e.target.getAttribute('data-target')}`)
                .then((response) => {
                    console.log(`NotificationBell::remove::response`, response);
                    setNotes(() => []);
                });
        }
    }

    function removeAll() {
        if (window.confirm('This will permanently remove all notifications. Are you sure?')) {
            supportAPI.delete(`/contact/${userContext.contact.contact_id}/notifications`)
                .then((response) => {
                    setNotes(() => []);
                    setShow(false);
                });
        }
    }

    function goToTicket(e) {
        fetchTicket(parseInt(e.target.getAttribute('data-target'))).then(t => {
            navigate(`/clients/${t.company_id}/tickets/${t.ticket_id}`);
            setShow(() => false);
        });
    }

    function emailSettingsHandler() {
        const prefs = {
            sendHourly: document.querySelector('input[name="email-setting-hourly"]').checked,
            sendDaily: document.querySelector('input[name="email-setting-daily"]').checked,
            sendWeekly: document.querySelector('input[name="email-setting-weekly"]').checked,
        };

        const data = {
            meta_key: 'note_prefs',
            meta_value: JSON.stringify(prefs)
        };

        supportAPI.put(`/contact/${userContext.contact.contact_id}/meta/note_prefs`,JSON.stringify(data) )
            .then(response => {
                setNotePrefs(()=>prefs);
                console.log('updatedNotePrefs', JSON.parse(response.data[0].meta_value));
                console.log('currentUserPrefs', JSON.parse(userContext.contact.meta.find(m=>m.meta_key==='note_prefs').meta_value));
                setUserContext({...userContext,contact:{...userContext.contact, meta: userContext.contact.meta.map(m=>m.meta_key==='note_prefs' ? response.data[0] : m)}});
                console.log('updatedUserPrefs', JSON.parse(userContext.contact.meta.find(m=>m.meta_key==='note_prefs').meta_value));


            });
    }

    function renderNotes() {
        if (notes.length > 0 && notes.map) {
            return notes.map(note => <div key={`note-${note.notification_id}`}
                                          className='notification-item p-2 ps-3 d-flex justify-content-between w-100'>
                <div className='flex-grow-1 me-2'
                     style={{textAlign: 'left'}}>{formatDate(note.date_created)} - {note.note}
                </div>
                <div className='text-nowrap ms-2'>
                    {note.ticket_id ? <i className='bi bi-box-arrow-up-right me-2' style={{cursor: 'pointer'}}
                                         data-target={note.ticket_id}
                                         onClick={goToTicket} title='Go to Ticket'/> : null}
                    <i className='bi bi-trash' style={{cursor: 'pointer'}} data-target={note.notification_id}
                       onClick={remove}
                       title='Delete Notification'/>
                </div>
            </div>);
        }
        return <div className='p-2 text-secondary'>You have no notifications.</div>;
    }

    return (
        <span className={`position-relative ms-2`} style={{width: '40px'}}>
            <div id='notification-bell' onClick={toggle}
                 className={`btn btn-small ${notes && notes.length > 0 ? 'btn-danger' : 'btn-secondary'}`}>
                <i className={`bi bi-bell-fill`} style={{color: 'white'}}/>
            </div>
            {show ?
                <div id='notification-modal' className='position-absolute bg-white text-secondary rounded shadow'
                     style={{zIndex: '9999', width: '350px', top: '-14px', right: '-8px'}}>
                    <div className='d-flex justify-content-between border-bottom p-2'>
                        <h5 style={{lineHeight: '40px', margin: '0px'}}>Notifications</h5>
                        <div>
                            {!showSettings ?
                                <div className={`btn btn-small bg-secondary me-1`} style={{height: '38px'}}
                                     onClick={() => setShowSettings(() => !showSettings)}>
                                    <i className='bi bi-gear-fill text-white'/>
                                </div>
                                :
                                <div className={`btn btn-small bg-light border-secondary me-1`} style={{height: '38px'}}
                                     onClick={() => setShowSettings(() => !showSettings)}>
                                    <i className='bi bi-gear-fill text-secondary'/>
                                </div>
                            }
                            <div className={`btn btn-small bg-secondary`} style={{height: '38px'}} onClick={toggle}>
                                <i className='bi bi-x text-white'/>
                            </div>
                        </div>
                    </div>
                    {showSettings ? <div className='py-2 px-3' style={{minHeight: '200px', cursor: 'pointer'}}>
                            <h6>Notification Settings</h6>
                            <Checkbox name={'email-setting-hourly'} label={'Send emails hourly.'} checked={notePrefs.sendHourly}
                                      changeHandler={(active)=>emailSettingsHandler(active)}/>
                            <Checkbox name={'email-setting-daily'} label={'Send emails daily.'} checked={notePrefs.sendDaily}
                                      changeHandler={(active)=>emailSettingsHandler(active)}/>
                            <Checkbox name={'email-setting-weekly'} label={'Send emails weekly.'} checked={notePrefs.sendWeekly}
                                      changeHandler={(active)=>emailSettingsHandler(active)}/>
                        </div>
                        : <div className='notification-wrapper overflow-auto'
                               style={{minHeight: '200px'}}>{renderNotes()}</div>}
                    {notes && notes.length > 0
                        ? <div className='rounded-bottom text-white py-3 bg-danger text-center'
                               style={{cursor: 'pointer'}} onClick={removeAll}>Delete All
                            Notifications</div>
                        : null}
                </div>
                : null}
        </span>
    );
}

export default NotificationBell;