/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 8/30/2024
 * for userx-support
 */

import React from "react";

export const ListModeSelector = ({selectedMode, changeHandler}) => {
    return (
        <div id='listmode-selector' className='d-flex bg-secondary px-3 py-2 text-white align-items-center'>
            <label htmlFor='company-select' className='text-nowrap me-2'><strong>Select
                View Mode:</strong></label>
            <select className='form-select' name='company_id'
                    value={selectedMode ? selectedMode : 'list'}
                    onChange={changeHandler}>
                <option value={`list`}>List</option>
                <option value={`kanban`}>Kanban</option>
            </select>
        </div>
    );
};