/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
import useLocalStorage from "./useLocalStorage";
import {useEffect} from "react";
import {fetchCompany, fetchCompanyContacts} from "../_utilities/companyUtilities";

/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 */

export default function useCompanyInfo(companyId) {
    const [info, setInfo] = useLocalStorage('uxCompanyInfo', {});
    const [contacts, setContacts] = useLocalStorage('uxCompanyContacts',[]);

    useEffect(() => {
        const fetchCompanyData = async (companyId) => {
            const companyInfo = await fetchCompany(companyId);
            setInfo(companyInfo);
            const contacts = await fetchCompanyContacts(companyId);
            setContacts(contacts);
        }

        if(companyId) {
            fetchCompanyData(companyId).then(()=>{/* do nothing */});
        }
    }, [companyId,setContacts,setInfo]);

    async function fetchCompanyData (companyId)  {
        const companyInfo = await fetchCompany(companyId);
        setInfo(companyInfo);
        const contacts = await fetchCompanyContacts(companyId);
        setContacts(contacts);
    }

    return {info, contacts, setInfo, setContacts, fetchCompanyData}
}