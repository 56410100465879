/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/6/2022
 * for support.userx.co
 */

import {gql, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
   uri: 'https://gql.waveapps.com/graphql/public',
});

const authLink = setContext((_, { headers }) => {
   // get the authentication token from local storage if it exists
   const token = localStorage.getItem('token');
   // return the headers to the context so httpLink can read them
   return {
      headers: {
         ...headers,
         authorization: `Bearer 4oYfZaYbbJkS0LhMslz04WwCs4kZLt`,
      }
   }
});

export const waveAPI = new ApolloClient({
   link: authLink.concat(httpLink),
   cache: new InMemoryCache()
});

export const GET_CLIENTS = gql`
query {
  business(id:"QnVzaW5lc3M6YThkYWZiY2UtMmQxYS00ZmJiLWE0MTEtYWZmOGIxZjU5Yzli"){
    customers{
      edges{
        node{
          id
          name
          firstName
          lastName
          email
          outstandingAmount{
            value
          }
        }
      }
    }
  }
}
`;