/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/23/2024
 * for userx-support
 */

import axios from 'axios';

const AI_API_KEY = process.env.REACT_APP_AI_API_KEY; // Store your key securely in .env

const openChatAPI =  axios.create({
    baseURL: 'https://api.openai.com/v1/chat/completions', // OpenAI completion endpoint
    headers: {
        'Authorization': `Bearer ${AI_API_KEY}`,
        'Content-Type': 'application/json',
    }
});

/**
 * Function to generate task list with retry logic for handling 429 Too Many Requests
 * @param {string} title - Ticket title
 * @param {string} description - Ticket description
 * @param {number} retries - Number of retry attempts (default 3)
 * @returns {Promise<Object>} - A JSON object representing the generated task list
 */
export const generateTaskList = async (title, description, retries = 3) => {
    try {
        // The messages array simulates a conversation
        const messages = [
            { role: 'system', content: 'You are a task manager assistant.' },
            { role: 'user', content: `Create a JSON array of concise task instructions based on the following ticket:
            Title: ${title}
            Description: ${description}

            Each task should be a simple, clear, actionable instruction as a string. Return the result as a JSON array of task strings.` }
        ];

        const response = await openChatAPI.post('', {
            model: 'gpt-3.5-turbo', // For chat-based models, this should be gpt-3.5-turbo or gpt-4
            messages: messages,      // Pass the messages array instead of prompt
            max_tokens: 150,         // Adjust based on your needs
            temperature: 0.7,        // Adjust based on the randomness you want in responses
        });

        let taskList = response.data.choices[0].message.content.trim();
        taskList = taskList.replace(/```json|```/g, '').trim();

        console.log('raw task JSON',taskList);

        // Try parsing the task list directly as a JSON array of instructions
        const parsedTaskList = JSON.parse(taskList);

        // Ensure the parsed response is an array and each element is a string (instruction)
        if (Array.isArray(parsedTaskList) && parsedTaskList.every(task => typeof task === 'string')) {
            return parsedTaskList; // Return the array of task instructions
        } else {
            throw new Error('Invalid response format. Expected an array of task strings.');
        }

    } catch (error) {
        if (error.response && error.response.status === 429) {
            const retryAfter = error.response.headers['retry-after'] || 2; // Default wait of 2 seconds if not specified
            console.warn(`Rate limit hit. Retrying in ${retryAfter} seconds...`);

            await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));

            // Retry logic with decreasing retry count
            if (retries > 0) {
                return generateTaskList(title, description, retries - 1);
            }
        }
        console.error('Error generating task list:', error);
        throw error;
    }
};

export const generateInvoiceSummary = async (items, retries=3) => {
    try {
        const fomattedItems = items.map(item => `${item.date} - ${item.description}`).join(', ');
        const messages = [
            { role: 'system', content: 'You are a helpful assistant that writes friendly and concise business messages, ' +
                                           'specifically for invoices and client communication. Keep responses short, polite, ' +
                                           'and professional. Add some seasonal flare if there is room' },
            { role: 'user', content: `Write a friendly 40-word invoice message thanking the client for their continued business, summarizing the completed work, and asking for prompt payment and offer assistance if they have any questions.
            Completed Work: ${fomattedItems}` }
        ];

        const response = await openChatAPI.post('', {
            model: 'gpt-3.5-turbo', // For chat-based models, this should be gpt-3.5-turbo or gpt-4
            messages: messages,      // Pass the messages array instead of prompt
            max_tokens: 150,         // Adjust based on your needs
            temperature: 0.7,        // Adjust based on the randomness you want in responses
        });

        console.log('generateInvoiceSummary::response', response);
        let summary = response.data.choices[0].message.content.trim();

        return summary;


    } catch(error) {
        if (error.response && error.response.status === 429) {
            const retryAfter = error.response.headers['retry-after'] || 2; // Default wait of 2 seconds if not specified
            console.warn(`Rate limit hit. Retrying in ${retryAfter} seconds...`);

            await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));

            // Retry logic with decreasing retry count
            if (retries > 0) {
                return generateInvoiceSummary(items, retries - 1);
            }
        }
        console.error('Error generating invoice summary',error.message);
    }
}



export const simpleTest = async () => {
    try {
        const response = await openChatAPI.post('', {
            model: 'gpt-3.5-turbo',
            prompt: 'Say hello!',
            max_tokens: 50,
        });
        console.log(response.data);
    } catch (error) {
        console.error('Error with simple request:', error);
    }
};