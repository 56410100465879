/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/27/2023
 * for userx-support
 */

import React from 'react';
import SelectorInput from "./_ui/formElements/SelectorInput";

const AdminStats = ()=>{
    return (
        <div className={`component-container d-flex flex-column h-100`}>

        </div>
    );
};

export default AdminStats;